.faq-box {
  border-radius: 6px;
  overflow: hidden;
}
.faq-box .accordion-item .accordion-header .accordion-button {
  position: relative;
  text-align: left;
}
.faq-box .accordion-item .accordion-header .accordion-button.collapsed:after {
  position: absolute;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background-image: none;
  content: '\f078';
  font-weight: 700;
  font-size: 10px;
  font-family: 'Font Awesome 5 Free';
}
.faq-box .accordion-item .accordion-header .accordion-button:after {
  position: absolute;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background-image: none;
  content: '\f077';
  font-weight: 700;
  font-size: 10px;
  font-family: 'Font Awesome 5 Free';
}
.faq-box .accordion-item .accordion-header .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}
.faq-box .accordion-item .accordion-header i {
  position: absolute;
  right: 25px;
  top: 50%;
  font-size: 14px;
  transform: translateY(-50%);
}
.faq-box .accordion-item .accordion-header {
  background-color: transparent;
}
.faq-box .accordion-item .accordion-header .accordion-button.collapsed {
  color: #fff !important;
  padding: 20px 20px !important;
  font-size: 18px;
  background-color: #0e0e0e90;
}
.faq-box .accordion-item {
  margin-bottom: 0px;
  background-color: transparent;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
}
.faq-box .accordion-item:last-child {
  margin-bottom: 0px;
  border-bottom: 0px !important;
}
.faq-box .accordion-item .accordion-header {
  padding: 0px;
  border-bottom: 0;
}
.faq-box .accordion-item .accordion-body {
  line-height: 24px;
  padding: 25px 25px 25px 25px;
  color: #fff;
  margin-top: 1px;
  background-color: #0e0e0e90;
}
.faq-box .accordion-item .accordion-header .accordion-button {
  color: #f44647 !important;
  padding: 20px !important;
  border-bottom: 0;
  font-size: 18px;
  border-radius: 0px;
  background-color: #0e0e0e90;
}
.faq-box .accordion-item .accordion-body ul {
  margin-left: 15px;
}
.faq-box .accordion-item .accordion-body ul li {
  list-style: circle;
}
/* have any question */
.have-any-question {
  border-radius: 6px;
  overflow: hidden;
  background-color: #0e0e0e90;
}
.have-any-question .question_form {
  border-top: 2px solid #f44647;
}
.have-any-question h5 {
  font-size: 16px;
}
.have-any-question .question_form input,
.have-any-question .question_form textarea {
  width: 100%;
  display: block;
  border-radius: 6px;
  color: #fff;
  border: 0px;
  background-color: rgba(255, 255, 255, 0.1);
}
.have-any-question .question_form input:focus,
.have-any-question .question_form textarea:focus {
  border: 1px solid #f44647;
}

.faq {
  background-image: url(../../../img/bg/faq.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;
  padding: 220px 0px;
  position: relative;
  z-index: 0;
}
.inner-page-title-area:after {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  top: 0px;
  left: 0px;
  z-index: -1;
}
.inner-page-title-area h1 {
  font-weight: 400;
  font-size: 36px;
  color: #fff;
  margin-top: 83px;
  line-height: 24px;
}
.inner-page-title-area h1 span {
  position: relative;
}
.inner-page-title-area h1 span:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  left: -35px;
  transform: translateY(-50%);
}
.inner-page-title-area h1 span:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  right: -35px;
  transform: translateY(-50%);
}
.inner-page-title-area .breadcrumb {
  margin: auto;
  text-align: center;
  padding: 0px;
  display: inline-block;
  background-color: transparent;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
}
.inner-page-title-area .breadcrumb li {
  float: none;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
.inner-page-title-area .breadcrumb li a {
  color: #f44647;
}
.inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
  color: #fff;
}
