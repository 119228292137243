@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  scroll-behavior: smooth;
}

body {
  cursor: url('./images/cursor.png'), auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

::selection {
  background-color: #f44647;
  color: #fff;
}
::-moz-selection {
  background-color: #f44647;
  color: #fff;
}
img {
  max-width: 100%;
}
/* body {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: #c5c5c5;
  letter-spacing: 0.5px;
  background-color: #202020;
} */

.greenColor {
  color: #008000;
}
.redColor {
  color: #ff0000;
}
.theme-bg-light {
  background-color: #1d4ddc;
}
.theme-bg-dark {
  background-color: #0e0e0e;
}
p {
  line-height: 24px;
  font-size: 14px;
  color: #6c757d;
  font-family: 'Roboto', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  color: #202020;
  padding: 0px;
  line-height: 1.2;
  letter-spacing: 1px;
  font-family: 'Roboto', sans-serif;
}
h1 {
  font-size: 60px;
  font-weight: 700;
}
h2 {
  font-size: 36px;
  font-weight: 700;
}
h3 {
  font-size: 26px;
  font-weight: 700;
}
h4 {
  font-size: 24px;
  font-weight: 700;
}
h5 {
  font-size: 18px;
  font-weight: 700;
}
h6 {
  font-size: 16px;
  font-weight: 700;
}
ul,
ol,
li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
/* --- Top Scroll --- */
.scroll-top {
  display: none;
  position: fixed;
  bottom: 2px;
  text-align: center;
  right: 2%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  color: #fff;
  background-color: #f44647;
  text-decoration: none;
  border-radius: 100%;
  z-index: 9999;
}
.scroll-top:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #d5292a;
}
/* --- Buttons --- */
.btn-style-1 {
  background-color: #f44647;
  color: #fff;
  border: 0;
  border-radius: 50px;
  padding: 12px 20px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btn-style-1:hover {
  background-color: #d5292a;
  color: #fff;
  text-decoration: none;
}
.btn-style-2 {
  color: #fff;
  background-color: #20202090;
  border-radius: 50px;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 22px;
  text-decoration: none;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btn-style-2:hover {
  background-color: #d5292a;
  color: #fff;
  text-decoration: none;
}
.btn-style-3 {
  color: #f44647;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
  letter-spacing: 1px;
  padding-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btn-style-3:hover {
  color: #fff;
  text-decoration: none;
  letter-spacing: 2px;
}
.btn-style-4 {
  background-color: #f44647;
  color: #fff;
  border: 0;
  margin: 10px 5px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btn-style-4:hover {
  background-color: #d5292a;
  color: #fff !important;
  text-decoration: none;
}
.btn-style-4:focus {
  background-color: #d5292a;
  color: #fff !important;
  text-decoration: none;
}
/* --- Loading --- */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  text-align: center;
  height: 100%;
  vertical-align: middle;
  height: 100%;
  overflow: visible;
  background-color: #fff;
}
#preloader .spinner-grow {
  top: 50%;
  position: relative;
}
/* --- section title --- */
.section-title h2 {
  font-weight: 600;
  font-size: 34px;
  text-transform: uppercase;
  position: relative;
  color: #fff;
}
.section-title .border-title {
  position: relative;
  height: 2px;
  background-color: #f44647;
  width: 160px;
  display: inline-block;
  border-radius: 100%;
}
.section-title .border-title:after {
  position: absolute;
  content: '';
  width: 110px;
  height: 1px;
  left: 0px;
  right: 0px;
  margin: auto;
  bottom: -3px;
  background-color: #fff;
  opacity: 0.3;
}
.section-title .border-title:before {
  position: absolute;
  content: '';
  width: 110px;
  height: 1px;
  left: 0px;
  right: 0px;
  margin: auto;
  bottom: 4px;
  background-color: #fff;
  opacity: 0.3;
}

/* Medium Devices, Desktops */
@media (max-width: 991px) {
  .btn-style-2 {
    color: #fff;
    background-color: #20202090;
    border-radius: 50px;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 22px;
    text-decoration: none;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .LoginSignUpBox,
  .forgotPasswordBox,
  .updateProfileBox,
  .resetPasswordBox,
  .updatePasswordBox {
    width: 80% !important;
  }

  .createServiceForm,
  .createSiteForm {
    width: 95% !important;
  }

  .serviceListContainer,
  .siteListContainer {
    padding: 0 0.5rem !important;
  }

  .LoginSignUpContainer,
  .forgotPasswordContainer,
  .resetPasswordContainer {
    height: 100vh !important;
    margin-top: 95px !important;
  }

  .updatePasswordContainer,
  .dashboard {
    height: 100vh !important;
    margin-top: 110px !important;
  }

  .qrcode {
    display: none !important;
  }

  .profile {
    margin-left: 50px !important;
  }
  .profile h1 {
    margin-left: 200px !important;
  }

  .slider {
    margin-top: 18px !important;
  }

  .header-container {
    padding: 0 1rem !important;
  }
  .header-social {
    display: none;
  }
  .header-lover .navbar .nav-item .nav-link {
    font-size: 12px;
    padding: 20px 10px;
  }
  .slider .carousel-item {
    height: 500px;
  }
  .slider .carousel-control-next,
  .slider .carousel-control-prev {
    top: 85% !important;
    background-color: #ff0000 !important;
    display: block;
  }
  .slider .carousel-caption h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .feature-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .about-images-1,
  .about-images-3 {
    display: none;
  }
  .footer-links li a {
    padding: 0 8px;
  }
}

/* Small Devices, Tablets */
@media (max-width: 767px) {
  .serviceListContainer,
  .siteListContainer {
    padding-bottom: 100px !important;
  }

  .profile {
    margin-left: 50px !important;
  }

  .profile h1 {
    margin-left: 0px !important;
  }

  .LoginSignUpContainer,
  .forgotPasswordContainer,
  .resetPasswordContainer,
  .updatePasswordContainer,
  .newServiceContainer,
  .newSiteContainer,
  .dashboard,
  .profileContainer {
    height: 100vh !important;
    margin-top: 70px !important;
  }

  .sidebar {
    margin-left: -50px !important;
    height: 80vh !important;
  }
  .dashboard_text {
    font-size: 16px !important;
  }
  .slider {
    margin-top: -20px !important;
  }

  .navbar-brand {
    margin-left: 1rem !important;
  }
  .navbar-toggler {
    margin-right: 1rem !important;
  }
  .header-container {
    padding: 0 0 !important;
  }
  .slider{
    margin-top: 60px !important;
  }
  .slider .carousel-caption .slider-caption-box {
    position: relative !important;
    background-color: #20202090 !important;
    padding: 30px !important;
    border-radius: 10px !important;
    border: 0px !important;
  }
  .slider .carousel-item {
    background-position: center;
    -webkit-background-size: contain;
    background-size: contain;
    height: 60vh !important;
    width: 100%;
  }
  .slider .carousel-caption p {
    display: none;
  }
  .slider-caption-box h2 {
    font-size: 20px !important;
  }

  .profile {
    margin-left: -30px !important;
  }
  .dashboard_text {
    font-size: 16px !important;
  }

  .btn-style-4 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .slider .carousel-control-next,
  .slider .carousel-control-prev {
    top: 90% !important;
    background-color: #ff0000 !important;
    display: block;
  }
  .feature-item {
    border-bottom: 0px;
  }
  .footer-links li {
    margin: 0px 0px 10px 0px !important;
    width: 49%;
  }
  .comment-classic {
    display: inline-block;
  }
  .comment-classic > * + * {
    margin-left: 0px;
  }
}

/* Extra Small Devices, Phones */
@media (max-width: 575px) {
  .slider .carousel-caption .slider-caption-box {
    position: relative !important;
    margin-top: 80px !important;
    background-color: #20202090 !important;
    padding: 30px !important;
    border-radius: 10px !important;
    border: 0px !important;
  }
  .serviceListContainer,
  .siteListContainer {
    padding-bottom: 100px !important;
  }

  .profile {
    margin-left: 50px !important;
  }

  .profile h1 {
    margin-left: 0px !important;
  }

  .LoginSignUpContainer,
  .forgotPasswordContainer,
  .resetPasswordContainer,
  .updatePasswordContainer,
  .newServiceContainer,
  .newSiteContainer,
  .dashboard,
  .profileContainer {
    height: 100vh !important;
    margin-top: 70px !important;
  }

  .sidebar {
    margin-left: -50px !important;
    height: 80vh !important;
  }
  .dashboard_text {
    font-size: 16px !important;
  }
  .slider {
    margin-top: -20px !important;
  }

  .navbar-brand {
    margin-left: 1rem !important;
  }
  .navbar-toggler {
    margin-right: 1rem !important;
  }
  .header-container {
    padding: 0 0 !important;
  }
  .slider{
    margin-top: 60px !important;
  }
  .slider .carousel-caption .slider-caption-box {
    position: relative !important;
    background-color: #20202090 !important;
    padding: 30px !important;
    border-radius: 10px !important;
    border: 0px !important;
  }
  .slider .carousel-item {
    background-position: center;
    -webkit-background-size: contain;
    background-size: contain;
    height: 60vh !important;
    width: 100%;
  }
  .slider .carousel-caption p {
    display: none;
  }
  .slider-caption-box h2 {
    font-size: 20px !important;
  }

  .profile {
    margin-left: -30px !important;
  }
  .dashboard_text {
    font-size: 16px !important;
  }

  .btn-style-4 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .slider .carousel-control-next,
  .slider .carousel-control-prev {
    top: 90% !important;
    background-color: #ff0000 !important;
    display: block;
  }
  .feature-item {
    border-bottom: 0px;
  }
  .footer-links li {
    margin: 0px 0px 10px 0px !important;
    width: 49%;
  }
  .comment-classic {
    display: inline-block;
  }
  .comment-classic > * + * {
    margin-left: 0px;
  }
}
