.features-area > div > div > div:nth-child(2n) .feature-item {
  background-color: rgba(139, 145, 221, 0.1);
}
.features-area > div > div > div {
  padding: 0;
}
.feature-item {
  text-align: center;
  padding: 50px 20px;
  background-color: #111;
}
.feature-item i {
  font-size: 40px;
  margin-bottom: 20px;
}
.feature-item h4 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 400;
}
