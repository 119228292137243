/* ===================================
	Slider
=================================== */
.slider .carousel-caption {
  top: 50%;
  bottom: auto;
  padding-top: 0px;
  left: 0px;
  right: 0px;
  padding-bottom: 0px;
  transform: translateY(-50%);
  text-align: left;
}
.slider .carousel-item {
  background-position: center center;
  -webkit-background-size: cover;
  margin-top: 0px;
  background-size: cover;
}
.slider .carousel-item.slider-one {
  background-image: url('../../img/slider/slider-1.jpg'); /*edit image*/
  height: 100vh;
}
.slider .carousel-item.slider-two {
  background-image: url('../../img/slider/slider-2.jpg'); /*edit image*/
  height: 100vh;
}
.slider .carousel-item.slider-three {
  background-image: url('../../img/slider/slider-4.jpg'); /*edit image*/
  height: 100vh;
}
.slider .carousel-caption .slider-caption-box {
  position: relative;
  background-color: rgba(244, 70, 71, 0.9);
  padding: 30px;
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.4);
}
.slider .carousel-caption h3 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 400;
  text-transform: capitalize;
}
.slider .carousel-caption h2 {
  font-size: 42px;
  color: #fff;
  font-weight: 700;
  line-height: 45px;
  text-transform: uppercase;
}
.slider .carousel-caption p {
  font-size: 15px;
  font-style: italic;
  line-height: 26px;
  margin-bottom: 20px;
  color: #fff;
}
.slider .carousel-control-next,
.slider .carousel-control-prev {
  border-radius: 100%;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
}
.slider .carousel-control-next span,
.slider .carousel-control-prev span {
  width: 12px;
  height: 12px;
}
.slider .carousel-control-next {
  bottom: auto;
  top: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  right: 30px;
  transform: translateY(-50%);
}
.slider .carousel-control-prev {
  bottom: auto;
  top: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  left: 30px;
  transform: translateY(-50%);
}

/* ===================================
	Service
=================================== */
.service-item {
  text-align: center;
}
.service-item .ser-img img {
  border-radius: 6px;
}
.service-item .description {
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  position: relative;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.service-item .description:before {
  width: 20px;
  position: absolute;
  content: '';
  height: 20px;
  margin: auto;
  left: 0px;
  right: 0px;
  top: -10px;
  z-index: -1;
  transform: rotate(45deg);
  background-color: rgba(255, 255, 255, 0.3);
  margin: auto;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.service-item h6 {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 10px;
}
.service-item:hover .description {
  border: 1px solid #f44647;
}
.service-item:hover .description:before {
  background-color: #f44647;
}
/* sidebar */
.sidebar-item-box {
  border-radius: 6px;
  overflow: hidden;
}
/* sidebar header */
.sidebar-header h5 {
  font-size: 18px;
  font-weight: 600;
  background-color: #f44647;
  padding: 20px 20px;
  margin-bottom: 0;
  color: #fff;
  text-transform: uppercase;
}
/* sidebar list */
.sidebar-list li {
  padding: 20px;
  background-color: #0e0e0e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar-list li a {
  display: flex;
  font-size: 14px;
  width: 100%;
  align-items: center;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.sidebar-list li:last-child {
  border-bottom: none;
}
.sidebar-list li i {
  margin-left: auto;
}
.sidebar-list li a:hover {
  color: #f44647;
}
/* sidebar contact */
.sidebar-contact {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.sidebar-contact li {
  padding: 20px;
  background-color: #0e0e0e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar-contact li a {
  display: flex;
  font-size: 14px;
  width: 100%;
  align-items: center;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.sidebar-contact li:last-child {
  border-bottom: none;
}
.sidebar-contact li a:hover {
  color: #f44647;
}
.sidebar-contact span {
  margin-left: auto;
  font-weight: 400;
  font-size: 14px;
  color: #8f8f8f;
}
/* download brochure */
.download-brochure a {
  width: 100%;
  display: inline-block;
  background-color: #f44647;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.download-brochure a i {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  padding-right: 10px;
  margin-right: 5px;
  font-size: 18px;
}
.download-brochure a:hover {
  background-color: #d5292a;
}
/* service detail img */
.service-detail-img img {
  border-radius: 6px;
}

/* ===================================
	Call to action
=================================== */
.call-to-action-area {
  background-image: url(../../img/bg/call-to-action-bg.jpg);
  background-size: cover;
  background-position: center top;
  position: relative;
  z-index: 0;
}
.call-to-action-area:after {
  position: absolute;
  content: '';
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
}
.cta-content h3 {
  font-size: 18px;
  color: #f44647;
  font-weight: 400;
  margin-bottom: 20px;
}
.call-to-action-area h2 {
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  margin-bottom: 20px;
}
.call-to-action-area h2 a {
  color: #fff;
  text-decoration: underline;
}
.call-to-action-area p {
  color: #fff;
  line-height: 28px;
}
.video-popup {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  color: #fff;
  font-size: 24px;
  border-radius: 100%;
  background-color: #f44647;
}
.video-popup:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #f44647;
  border-radius: 100%;
  top: 0;
  animation-fill-mode: both;
  animation: witr_squares 1.5s ease-in-out infinite;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.video-popup:after {
  position: absolute;
  content: '';
  width: 90px;
  height: 90px;
  border: 2px solid #f44647;
  border-radius: 100%;
  top: -5px;
  left: -5px;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.video-popup i {
  z-index: 2;
  position: relative;
}
.video-popup:hover {
  color: #fff;
}
/* witr_squares_ */
@keyframes witr_squares {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  20% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

/* .banner {
  background-image: url("../../images/cover.jfif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vmin;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.banner > h1 {
  margin: 5vmax;

  font: 600 2.5vmax "Roboto";
}

.banner > p {
  font: 300 1.4vmax "Lucida Sans";
}

.banner > a > button {
  margin-bottom: 5vmax;
  cursor: pointer;
  background-color: white;
  border: 1px solid white;
  border-radius: 0;
  padding: 1vmax;
  transition: all 0.5s;
  width: 9vmax;
  font: 500 1vmax "Roboto";
}
.banner > a > button:hover {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}

.banner::after {
  content: "";
  width: 100vw;
  height: 100vmin;
  background-color: #ffffff;
  position: absolute;
  top: 0%;
  left: 0;
  clip-path: polygon(100% 68%, 0 100%, 100% 100%);
  max-width: 100%;
}

.homeHeading {
  text-align: center;
  font-family: Roboto;
  font-size: 1.4vmax;
  border-bottom: 1px solid rgba(21, 21, 21, 0.5);
  width: 20vmax;
  padding: 1vmax;
  margin: 5vmax auto;
  color: rgb(0, 0, 0, 0.7);
}

.container {
  display: flex;
  margin: 2vmax auto;
  width: 80vw;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}

.productCard {
  width: 14vmax;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgb(48, 48, 48);
  margin: 2vmax;
  transition: all 0.5s;
  padding-bottom: 0.5vmax;
}

.productCard > img {
  width: 14vmax;
}

.productCard > div {
  margin: 0.5vmax;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.productCardSpan {
  margin: 0.5vmax;
  font: 300 0.7vmax "Roboto";
}

.productCard > p {
  font-family: "Roboto";
  font-size: 1.2vmax;
  margin: 1vmax 0.5vmax;
  margin-bottom: 0;
}

.productCard > span {
  margin: 0.5vmax;
  color: tomato;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 1vmax;
}

.productCard:hover {
  box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);

  transform: translateY(-1vmax);
}

@media screen and (max-width: 600px) {
  .productCard > p {
    font-size: 1.7vmax;
  }

  .productCard > div {
    margin: 0vmax;
    display: block;
  }

  .productCard > span {
    font-size: 1.5vmax;
  }

  .productCard > div > span {
    margin: 0 0.5vmax;
    font: 300 1vmax "Roboto";
  }
} */
