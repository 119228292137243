@charset "utf-8";
/* CSS Document */

/*========================================================================
	
	Table of contents
	
	01. Transition CSS
	02. Font Size
	03. Social icon color
	04. Padding
	05. Margin
	06. Border

========================================================================*/

/*--------------------------
	01. Transition CSS
--------------------------*/
.transition-1s {
	-webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}
.transition-02s {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.transition-03s {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.transition-04s {
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.transition-05s {
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.transition-06s {
	-webkit-transition: all 0.6s ease-in-out;
	-moz-transition: all 0.6s ease-in-out;
	-o-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
}
/*--------------------------
	02. Font Size
--------------------------*/
.font-size-12 {
	font-size: 12px;
}
.font-size-13 {
	font-size: 13px;
}
.font-size-14 {
	font-size: 14px;
}
.font-size-15 {
	font-size: 15px;
}
.font-size-16 {
	font-size: 16px;
}
.font-size-17 {
	font-size: 17px;
}
.font-size-18 {
	font-size: 18px;
}
.font-size-19 {
	font-size: 19px;
}
.font-size-20 {
	font-size: 20px;
}
.font-size-21 {
	font-size: 21px;
}
.font-size-22 {
	font-size: 22px;
}
.font-size-23 {
	font-size: 23px;
}
.font-size-24 {
	font-size: 24px;
}
.font-size-25 {
	font-size: 25px;
}
.font-size-26 {
	font-size: 26px;
}
.font-size-27 {
	font-size: 27px;
}
.font-size-28 {
	font-size: 28px;
}
.font-size-29 {
	font-size: 29px;
}
.font-size-30 {
	font-size: 30px;
}
.font-size-31 {
	font-size: 31px;
}
.font-size-32 {
	font-size: 32px;
}
.font-size-33 {
	font-size: 33px;
}
.font-size-34 {
	font-size: 34px;
}
.font-size-35 {
	font-size: 35px;
}
.font-size-36 {
	font-size: 36px;
}
.font-size-37 {
	font-size: 37px;
}
.font-size-38 {
	font-size: 38px;
}
.font-size-39 {
	font-size: 39px;
}
.font-size-40 {
	font-size: 40px;
}
.font-size-41 {
	font-size: 41px;
}
.font-size-42 {
	font-size: 42px;
}
.font-size-43 {
	font-size: 43px;
}
.font-size-44 {
	font-size: 44px;
}
.font-size-45 {
	font-size: 45px;
}
.font-size-46 {
	font-size: 46px;
}
.font-size-47 {
	font-size: 47px;
}
.font-size-48 {
	font-size: 48px;
}
.font-size-49 {
	font-size: 49px;
}
.font-size-50 {
	font-size: 50px;
}
.font-size-51 {
	font-size: 51px;
}
.font-size-52 {
	font-size: 52px;
}
.font-size-53 {
	font-size: 53px;
}
.font-size-54 {
	font-size: 54px;
}
.font-size-55 {
	font-size: 55px;
}
.font-size-56 {
	font-size: 56px;
}
.font-size-57 {
	font-size: 57px;
}
.font-size-58 {
	font-size: 58px;
}
.font-size-59 {
	font-size: 59px;
}
.font-size-60 {
	font-size: 60px;
}
/*--------------------------
	03. Social icon color
--------------------------*/
/* Social color */
.facebook-color {
	color: #3a559f;
}
.twitter-color {
	color: #2aaae0;
}
.linkedin-color {
	color: #007bb5;
}
.google-plus-color {
	color: #dc4335;
}
.pinterest-color {
	color: #cb2027;
}
.whatsapp-color {
	color: #0dc143;
}
.youtube-color {
	color: #e42b33;
}
.instagram-color {
	color: #c82d9e;
}
.skype-color {
	color: #31c5ed;
}
/* Social background color */
.facebook-bg-color {
	background-color: #3a559f;
}
.twitter-bg-color {
	background-color: #2aaae0;
}
.linkedin-bg-color {
	background-color: #007bb5;
}
.google-plus-bg-color {
	background-color: #dc4335;
}
.pinterest-bg-color {
	background-color: #cb2027;
}
.whatsapp-bg-color {
	background-color: #0dc143;
}
.youtube-bg-color {
	background-color: #e42b33;
}
.instagram-bg-color {
	background-color: #c82d9e;
}
.skype-bg-color {
	background-color: #31c5ed;
}
/*--------------------------
	04. padding
--------------------------*/
/* padding all */
.p-6 {
	padding: 6px;
}
.p-7 {
	padding: 7px;
}
.p-8 {
	padding: 8px;
}
.p-9 {
	padding: 9px;
}
.p-10 {
	padding: 10px;
}
.p-11 {
	padding: 11px;
}
.p-12 {
	padding: 12px;
}
.p-13 {
	padding: 13px;
}
.p-14 {
	padding: 14px;
}
.p-15 {
	padding: 15px;
}
.p-16 {
	padding: 16px;
}
.p-17 {
	padding: 17px;
}
.p-18 {
	padding: 18px;
}
.p-19 {
	padding: 19px;
}
.p-20 {
	padding: 20px;
}
.p-21 {
	padding: 21px;
}
.p-22 {
	padding: 22px;
}
.p-23 {
	padding: 23px;
}
.p-24 {
	padding: 24px;
}
.p-25 {
	padding: 25px;
}
.p-26 {
	padding: 26px;
}
.p-27 {
	padding: 27px;
}
.p-28 {
	padding: 28px;
}
.p-29 {
	padding: 29px;
}
.p-30 {
	padding: 30px;
}
.p-31 {
	padding: 31px;
}
.p-32 {
	padding: 32px;
}
.p-33 {
	padding: 33px;
}
.p-34 {
	padding: 34px;
}
.p-35 {
	padding: 35px;
}
.p-36 {
	padding: 36px;
}
.p-37 {
	padding: 37px;
}
.p-38 {
	padding: 38px;
}
.p-39 {
	padding: 39px;
}
.p-40 {
	padding: 40px;
}
.p-45 {
	padding: 45px;
}
.p-50 {
	padding: 50px;
}
.p-55 {
	padding: 55px;
}
.p-60 {
	padding: 60px;
}
.p-65 {
	padding: 65px;
}
.p-70 {
	padding: 70px;
}
.p-75 {
	padding: 75px;
}
.p-80 {
	padding: 80px;
}
.p-85 {
	padding: 85px;
}
.p-90 {
	padding: 90px;
}
.p-95 {
	padding: 95px;
}
.p-100 {
	padding: 100px;
}
.p-105 {
	padding: 105px;
}
.p-110 {
	padding: 110px;
}
.p-115 {
	padding: 115px;
}
.p-120 {
	padding: 120px;
}
.p-125 {
	padding: 125px;
}
.p-130 {
	padding: 135px;
}
.p-135 {
	padding: 135px;
}
.p-140 {
	padding: 140px;
}
.p-145 {
	padding: 145px;
}
.p-150 {
	padding: 150px;
}
/* padding top */
.pt-6 {
	padding-top: 6px;
}
.pt-7 {
	padding-top: 7px;
}
.pt-8 {
	padding-top: 8px;
}
.pt-9 {
	padding-top: 9px;
}
.pt-10 {
	padding-top: 10px;
}
.pt-11 {
	padding-top: 11px;
}
.pt-12 {
	padding-top: 12px;
}
.pt-13 {
	padding-top: 13px;
}
.pt-14 {
	padding-top: 14px;
}
.pt-15 {
	padding-top: 15px;
}
.pt-16 {
	padding-top: 16px;
}
.pt-17 {
	padding-top: 17px;
}
.pt-18 {
	padding-top: 18px;
}
.pt-19 {
	padding-top: 19px;
}
.pt-20 {
	padding-top: 20px;
}
.pt-21 {
	padding-top: 21px;
}
.pt-22 {
	padding-top: 22px;
}
.pt-23 {
	padding-top: 23px;
}
.pt-24 {
	padding-top: 24px;
}
.pt-25 {
	padding-top: 25px;
}
.pt-26 {
	padding-top: 26px;
}
.pt-27 {
	padding-top: 27px;
}
.pt-28 {
	padding-top: 28px;
}
.pt-29 {
	padding-top: 29px;
}
.pt-30 {
	padding-top: 30px;
}
.pt-31 {
	padding-top: 31px;
}
.pt-32 {
	padding-top: 32px;
}
.pt-33 {
	padding-top: 33px;
}
.pt-34 {
	padding-top: 34px;
}
.pt-35 {
	padding-top: 35px;
}
.pt-36 {
	padding-top: 36px;
}
.pt-37 {
	padding-top: 37px;
}
.pt-38 {
	padding-top: 38px;
}
.pt-39 {
	padding-top: 39px;
}
.pt-40 {
	padding-top: 40px;
}
.pt-45 {
	padding-top: 45px;
}
.pt-50 {
	padding-top: 50px;
}
.pt-55 {
	padding-top: 55px;
}
.pt-60 {
	padding-top: 60px;
}
.pt-65 {
	padding-top: 65px;
}
.pt-70 {
	padding-top: 70px;
}
.pt-75 {
	padding-top: 75px;
}
.pt-80 {
	padding-top: 80px;
}
.pt-85 {
	padding-top: 85px;
}
.pt-90 {
	padding-top: 90px;
}
.pt-95 {
	padding-top: 95px;
}
.pt-100 {
	padding-top: 100px;
}
.pt-105 {
	padding-top: 105px;
}
.pt-110 {
	padding-top: 110px;
}
.pt-115 {
	padding-top: 115px;
}
.pt-120 {
	padding-top: 120px;
}
.pt-125 {
	padding-top: 125px;
}
.pt-130 {
	padding-top: 130px;
}
.pt-135 {
	padding-top: 135px;
}
.pt-140 {
	padding-top: 140px;
}
.pt-145 {
	padding-top: 145px;
}
.pt-150 {
	padding-top: 150px;
}
/* padding bottom */
.pb-6 {
	padding-bottom: 6px;
}
.pb-7 {
	padding-bottom: 7px;
}
.pb-8 {
	padding-bottom: 8px;
}
.pb-9 {
	padding-bottom: 9px;
}
.pb-10 {
	padding-bottom: 10px;
}
.pb-11 {
	padding-bottom: 11px;
}
.pb-12 {
	padding-bottom: 12px;
}
.pb-13 {
	padding-bottom: 13px;
}
.pb-14 {
	padding-bottom: 14px;
}
.pb-15 {
	padding-bottom: 15px;
}
.pb-16 {
	padding-bottom: 16px;
}
.pb-17 {
	padding-bottom: 17px;
}
.pb-18 {
	padding-bottom: 18px;
}
.pb-19 {
	padding-bottom: 19px;
}
.pb-20 {
	padding-bottom: 20px;
}
.pb-21 {
	padding-bottom: 21px;
}
.pb-22 {
	padding-bottom: 22px;
}
.pb-23 {
	padding-bottom: 23px;
}
.pb-24 {
	padding-bottom: 24px;
}
.pb-25 {
	padding-bottom: 25px;
}
.pb-26 {
	padding-bottom: 26px;
}
.pb-27 {
	padding-bottom: 27px;
}
.pb-28 {
	padding-bottom: 28px;
}
.pb-29 {
	padding-bottom: 29px;
}
.pb-30 {
	padding-bottom: 30px;
}
.pb-31 {
	padding-bottom: 31px;
}
.pb-32 {
	padding-bottom: 32px;
}
.pb-33 {
	padding-bottom: 33px;
}
.pb-34 {
	padding-bottom: 34px;
}
.pb-35 {
	padding-bottom: 35px;
}
.pb-36 {
	padding-bottom: 36px;
}
.pb-37 {
	padding-bottom: 37px;
}
.pb-38 {
	padding-bottom: 38px;
}
.pb-39 {
	padding-bottom: 39px;
}
.pb-40 {
	padding-bottom: 40px;
}
.pb-45 {
	padding-bottom: 45px;
}
.pb-50 {
	padding-bottom: 50px;
}
.pb-55 {
	padding-bottom: 55px;
}
.pb-60 {
	padding-bottom: 60px;
}
.pb-65 {
	padding-bottom: 65px;
}
.pb-70 {
	padding-bottom: 70px;
}
.pb-75 {
	padding-bottom: 75px;
}
.pb-80 {
	padding-bottom: 80px;
}
.pb-85 {
	padding-bottom: 85px;
}
.pb-90 {
	padding-bottom: 90px;
}
.pb-95 {
	padding-bottom: 95px;
}
.pb-100 {
	padding-bottom: 100px;
}
.pb-105 {
	padding-bottom: 105px;
}
.pb-110 {
	padding-bottom: 110px;
}
.pb-115 {
	padding-bottom: 115px;
}
.pb-120 {
	padding-bottom: 120px;
}
.pb-125 {
	padding-bottom: 125px;
}
.pb-130 {
	padding-bottom: 130px;
}
.pb-135 {
	padding-bottom: 135px;
}
.pb-140 {
	padding-bottom: 140px;
}
.pb-145 {
	padding-bottom: 145px;
}
.pb-150 {
	padding-bottom: 150px;
}
/* padding left */
.pl-6 {
	padding-left: 6px;
}
.pl-7 {
	padding-left: 7px;
}
.pl-8 {
	padding-left: 8px;
}
.pl-9 {
	padding-left: 9px;
}
.pl-10 {
	padding-left: 10px;
}
.pl-11 {
	padding-left: 11px;
}
.pl-12 {
	padding-left: 12px;
}
.pl-13 {
	padding-left: 13px;
}
.pl-14 {
	padding-left: 14px;
}
.pl-15 {
	padding-left: 15px;
}
.pl-16 {
	padding-left: 16px;
}
.pl-17 {
	padding-left: 17px;
}
.pl-18 {
	padding-left: 18px;
}
.pl-19 {
	padding-left: 19px;
}
.pl-20 {
	padding-left: 20px;
}
.pl-21 {
	padding-left: 21px;
}
.pl-22 {
	padding-left: 22px;
}
.pl-23 {
	padding-left: 23px;
}
.pl-24 {
	padding-left: 24px;
}
.pl-25 {
	padding-left: 25px;
}
.pl-26 {
	padding-left: 26px;
}
.pl-27 {
	padding-left: 27px;
}
.pl-28 {
	padding-left: 28px;
}
.pl-29 {
	padding-left: 29px;
}
.pl-30 {
	padding-left: 30px;
}
.pl-31 {
	padding-left: 31px;
}
.pl-32 {
	padding-left: 32px;
}
.pl-33 {
	padding-left: 33px;
}
.pl-34 {
	padding-left: 34px;
}
.pl-35 {
	padding-left: 35px;
}
.pl-36 {
	padding-left: 36px;
}
.pl-37 {
	padding-left: 37px;
}
.pl-38 {
	padding-left: 38px;
}
.pl-39 {
	padding-left: 39px;
}
.pl-40 {
	padding-left: 40px;
}
.pl-45 {
	padding-left: 45px;
}
.pl-50 {
	padding-left: 50px;
}
.pl-55 {
	padding-left: 55px;
}
.pl-60 {
	padding-left: 60px;
}
.pl-65 {
	padding-left: 65px;
}
.pl-70 {
	padding-left: 70px;
}
.pl-75 {
	padding-left: 75px;
}
.pl-80 {
	padding-left: 80px;
}
.pl-85 {
	padding-left: 85px;
}
.pl-90 {
	padding-left: 90px;
}
.pl-95 {
	padding-left: 95px;
}
.pl-100 {
	padding-left: 100px;
}
.pl-105 {
	padding-left: 105px;
}
.pl-110 {
	padding-left: 110px;
}
.pl-115 {
	padding-left: 115px;
}
.pl-120 {
	padding-left: 120px;
}
.pl-125 {
	padding-left: 125px;
}
.pl-130 {
	padding-left: 130px;
}
.pl-135 {
	padding-left: 135px;
}
.pl-140 {
	padding-left: 140px;
}
.pl-145 {
	padding-left: 145px;
}
.pl-150 {
	padding-left: 150px;
}
/* padding right */
.pr-6 {
	padding-right: 6px;
}
.pr-7 {
	padding-right: 7px;
}
.pr-8 {
	padding-right: 8px;
}
.pr-9 {
	padding-right: 9px;
}
.pr-10 {
	padding-right: 10px;
}
.pr-11 {
	padding-right: 11px;
}
.pr-12 {
	padding-right: 12px;
}
.pr-13 {
	padding-right: 13px;
}
.pr-14 {
	padding-right: 14px;
}
.pr-15 {
	padding-right: 15px;
}
.pr-16 {
	padding-right: 16px;
}
.pr-17 {
	padding-right: 17px;
}
.pr-18 {
	padding-right: 18px;
}
.pr-19 {
	padding-right: 19px;
}
.pr-20 {
	padding-right: 20px;
}
.pr-21 {
	padding-right: 21px;
}
.pr-22 {
	padding-right: 22px;
}
.pr-23 {
	padding-right: 23px;
}
.pr-24 {
	padding-right: 24px;
}
.pr-25 {
	padding-right: 25px;
}
.pr-26 {
	padding-right: 26px;
}
.pr-27 {
	padding-right: 27px;
}
.pr-28 {
	padding-right: 28px;
}
.pr-29 {
	padding-right: 29px;
}
.pr-30 {
	padding-right: 30px;
}
.pr-31 {
	padding-right: 31px;
}
.pr-32 {
	padding-right: 32px;
}
.pr-33 {
	padding-right: 33px;
}
.pr-34 {
	padding-right: 34px;
}
.pr-35 {
	padding-right: 35px;
}
.pr-36 {
	padding-right: 36px;
}
.pr-37 {
	padding-right: 37px;
}
.pr-38 {
	padding-right: 38px;
}
.pr-39 {
	padding-right: 39px;
}
.pr-40 {
	padding-right: 40px;
}
.pr-45 {
	padding-right: 45px;
}
.pr-50 {
	padding-right: 50px;
}
.pr-55 {
	padding-right: 55px;
}
.pr-60 {
	padding-right: 60px;
}
.pr-65 {
	padding-right: 65px;
}
.pr-70 {
	padding-right: 70px;
}
.pr-75 {
	padding-right: 75px;
}
.pr-80 {
	padding-right: 80px;
}
.pr-85 {
	padding-right: 85px;
}
.pr-90 {
	padding-right: 90px;
}
.pr-95 {
	padding-right: 95px;
}
.pr-100 {
	padding-right: 100px;
}
.pr-105 {
	padding-right: 105px;
}
.pr-110 {
	padding-right: 110px;
}
.pr-115 {
	padding-right: 115px;
}
.pr-120 {
	padding-right: 120px;
}
.pr-125 {
	padding-right: 125px;
}
.pr-130 {
	padding-right: 130px;
}
.pr-135 {
	padding-right: 135px;
}
.pr-140 {
	padding-right: 140px;
}
.pr-145 {
	padding-right: 145px;
}
.pr-150 {
	padding-right: 150px;
}
/*--------------------------
	05. margin
--------------------------*/
/* margin all */
.m-6 {
	margin: 6px;
}
.m-7 {
	margin: 7px;
}
.m-8 {
	margin: 8px;
}
.m-9 {
	margin: 9px;
}
.m-10 {
	margin: 10px;
}
.m-11 {
	margin: 11px;
}
.m-12 {
	margin: 12px;
}
.m-13 {
	margin: 13px;
}
.m-14 {
	margin: 14px;
}
.m-15 {
	margin: 15px;
}
.m-16 {
	margin: 16px;
}
.m-17 {
	margin: 17px;
}
.m-18 {
	margin: 18px;
}
.m-19 {
	margin: 19px;
}
.m-20 {
	margin: 20px;
}
.m-21 {
	margin: 21px;
}
.m-22 {
	margin: 22px;
}
.m-23 {
	margin: 23px;
}
.m-24 {
	margin: 24px;
}
.m-25 {
	margin: 25px;
}
.m-26 {
	margin: 26px;
}
.m-27 {
	margin: 27px;
}
.m-28 {
	margin: 28px;
}
.m-29 {
	margin: 29px;
}
.m-30 {
	margin: 30px;
}
.m-31 {
	margin: 31px;
}
.m-32 {
	margin: 32px;
}
.m-33 {
	margin: 33px;
}
.m-34 {
	margin: 34px;
}
.m-35 {
	margin: 35px;
}
.m-36 {
	margin: 36px;
}
.m-37 {
	margin: 37px;
}
.m-38 {
	margin: 38px;
}
.m-39 {
	margin: 39px;
}
.m-40 {
	margin: 40px;
}
.m-45 {
	margin: 45px;
}
.m-50 {
	margin: 50px;
}
.m-55 {
	margin: 55px;
}
.m-60 {
	margin: 60px;
}
.m-65 {
	margin: 65px;
}
.m-70 {
	margin: 70px;
}
.m-75 {
	margin: 75px;
}
.m-80 {
	margin: 80px;
}
.m-85 {
	margin: 85px;
}
.m-90 {
	margin: 90px;
}
.m-95 {
	margin: 95px;
}
.m-100 {
	margin: 100px;
}
.m-105 {
	margin: 105px;
}
.m-110 {
	margin: 110px;
}
.m-115 {
	margin: 115px;
}
.m-120 {
	margin: 120px;
}
.m-125 {
	margin: 125px;
}
.m-130 {
	margin: 130px;
}
.m-135 {
	margin: 135px;
}
.m-140 {
	margin: 140px;
}
.m-145 {
	margin: 145px;
}
.m-150 {
	margin: 150px;
}
/* margin top */
.mt-6 {
	margin-top: 6px;
}
.mt-7 {
	margin-top: 7px;
}
.mt-8 {
	margin-top: 8px;
}
.mt-9 {
	margin-top: 9px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-11 {
	margin-top: 11px;
}
.mt-12 {
	margin-top: 12px;
}
.mt-13 {
	margin-top: 13px;
}
.mt-14 {
	margin-top: 14px;
}
.mt-15 {
	margin-top: 15px;
}
.mt-16 {
	margin-top: 16px;
}
.mt-17 {
	margin-top: 17px;
}
.mt-18 {
	margin-top: 18px;
}
.mt-19 {
	margin-top: 19px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-21 {
	margin-top: 21px;
}
.mt-22 {
	margin-top: 22px;
}
.mt-23 {
	margin-top: 23px;
}
.mt-24 {
	margin-top: 24px;
}
.mt-25 {
	margin-top: 25px;
}
.mt-26 {
	margin-top: 26px;
}
.mt-27 {
	margin-top: 27px;
}
.mt-28 {
	margin-top: 28px;
}
.mt-29 {
	margin-top: 29px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-31 {
	margin-top: 31px;
}
.mt-32 {
	margin-top: 32px;
}
.mt-33 {
	margin-top: 33px;
}
.mt-34 {
	margin-top: 34px;
}
.mt-35 {
	margin-top: 35px;
}
.mt-36 {
	margin-top: 36px;
}
.mt-37 {
	margin-top: 37px;
}
.mt-38 {
	margin-top: 38px;
}
.mt-39 {
	margin-top: 39px;
}
.mt-40 {
	margin-top: 40px;
}
.mt-45 {
	margin-top: 45px;
}
.mt-50 {
	margin-top: 50px;
}
.mt-55 {
	margin-top: 55px;
}
.mt-60 {
	margin-top: 60px;
}
.mt-65 {
	margin-top: 65px;
}
.mt-70 {
	margin-top: 70px;
}
.mt-75 {
	margin-top: 75px;
}
.mt-80 {
	margin-top: 80px;
}
.mt-85 {
	margin-top: 85px;
}
.mt-90 {
	margin-top: 90px;
}
.mt-95 {
	margin-top: 95px;
}
.mt-100 {
	margin-top: 100px;
}
.mt-105 {
	margin-top: 105px;
}
.mt-110 {
	margin-top: 110px;
}
.mt-115 {
	margin-top: 115px;
}
.mt-120 {
	margin-top: 120px;
}
.mt-125 {
	margin-top: 125px;
}
.mt-130 {
	margin-top: 130px;
}
.mt-135 {
	margin-top: 135px;
}
.mt-140 {
	margin-top: 140px;
}
.mt-145 {
	margin-top: 145px;
}
.mt-150 {
	margin-top: 150px;
}
/* margin bottom */
.mb-6 {
	margin-bottom: 6px;
}
.mb-7 {
	margin-bottom: 7px;
}
.mb-8 {
	margin-bottom: 8px;
}
.mb-9 {
	margin-bottom: 9px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-11 {
	margin-bottom: 11px;
}
.mb-12 {
	margin-bottom: 12px;
}
.mb-13 {
	margin-bottom: 13px;
}
.mb-14 {
	margin-bottom: 14px;
}
.mb-15 {
	margin-bottom: 15px;
}
.mb-16 {
	margin-bottom: 16px;
}
.mb-17 {
	margin-bottom: 17px;
}
.mb-18 {
	margin-bottom: 18px;
}
.mb-19 {
	margin-bottom: 19px;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-21 {
	margin-bottom: 21px;
}
.mb-22 {
	margin-bottom: 22px;
}
.mb-23 {
	margin-bottom: 23px;
}
.mb-24 {
	margin-bottom: 24px;
}
.mb-25 {
	margin-bottom: 25px;
}
.mb-26 {
	margin-bottom: 26px;
}
.mb-27 {
	margin-bottom: 27px;
}
.mb-28 {
	margin-bottom: 28px;
}
.mb-29 {
	margin-bottom: 29px;
}
.mb-30 {
	margin-bottom: 30px;
}
.mb-31 {
	margin-bottom: 31px;
}
.mb-32 {
	margin-bottom: 32px;
}
.mb-33 {
	margin-bottom: 33px;
}
.mb-34 {
	margin-bottom: 34px;
}
.mb-35 {
	margin-bottom: 35px;
}
.mb-36 {
	margin-bottom: 36px;
}
.mb-37 {
	margin-bottom: 37px;
}
.mb-38 {
	margin-bottom: 38px;
}
.mb-39 {
	margin-bottom: 39px;
}
.mb-40 {
	margin-bottom: 40px;
}
.mb-45 {
	margin-bottom: 45px;
}
.mb-50 {
	margin-bottom: 50px;
}
.mb-55 {
	margin-bottom: 55px;
}
.mb-60 {
	margin-bottom: 60px;
}
.mb-65 {
	margin-bottom: 65px;
}
.mb-70 {
	margin-bottom: 70px;
}
.mb-75 {
	margin-bottom: 75px;
}
.mb-80 {
	margin-bottom: 80px;
}
.mb-85 {
	margin-bottom: 85px;
}
.mb-90 {
	margin-bottom: 90px;
}
.mb-95 {
	margin-bottom: 95px;
}
.mb-100 {
	margin-bottom: 100px;
}
.mb-105 {
	margin-bottom: 105px;
}
.mb-110 {
	margin-bottom: 110px;
}
.mb-115 {
	margin-bottom: 115px;
}
.mb-120 {
	margin-bottom: 120px;
}
.mb-125 {
	margin-bottom: 125px;
}
.mb-130 {
	margin-bottom: 130px;
}
.mb-135 {
	margin-bottom: 135px;
}
.mb-140 {
	margin-bottom: 140px;
}
.mb-145 {
	margin-bottom: 145px;
}
.mb-150 {
	margin-bottom: 150px;
}
/* margin left */
.ml-6 {
	margin-left: 6px;
}
.ml-7 {
	margin-left: 7px;
}
.ml-8 {
	margin-left: 8px;
}
.ml-9 {
	margin-left: 9px;
}
.ml-10 {
	margin-left: 10px;
}
.ml-11 {
	margin-left: 11px;
}
.ml-12 {
	margin-left: 12px;
}
.ml-13 {
	margin-left: 13px;
}
.ml-14 {
	margin-left: 14px;
}
.ml-15 {
	margin-left: 15px;
}
.ml-16 {
	margin-left: 16px;
}
.ml-17 {
	margin-left: 17px;
}
.ml-18 {
	margin-left: 18px;
}
.ml-19 {
	margin-left: 19px;
}
.ml-20 {
	margin-left: 20px;
}
.ml-21 {
	margin-left: 21px;
}
.ml-22 {
	margin-left: 22px;
}
.ml-23 {
	margin-left: 23px;
}
.ml-24 {
	margin-left: 24px;
}
.ml-25 {
	margin-left: 25px;
}
.ml-26 {
	margin-left: 26px;
}
.ml-27 {
	margin-left: 27px;
}
.ml-28 {
	margin-left: 28px;
}
.ml-29 {
	margin-left: 29px;
}
.ml-30 {
	margin-left: 30px;
}
.ml-31 {
	margin-left: 31px;
}
.ml-32 {
	margin-left: 32px;
}
.ml-33 {
	margin-left: 33px;
}
.ml-34 {
	margin-left: 34px;
}
.ml-35 {
	margin-left: 35px;
}
.ml-36 {
	margin-left: 36px;
}
.ml-37 {
	margin-left: 37px;
}
.ml-38 {
	margin-left: 38px;
}
.ml-39 {
	margin-left: 39px;
}
.ml-40 {
	margin-left: 40px;
}
.ml-45 {
	margin-left: 45px;
}
.ml-50 {
	margin-left: 50px;
}
.ml-55 {
	margin-left: 55px;
}
.ml-60 {
	margin-left: 60px;
}
.ml-65 {
	margin-left: 65px;
}
.ml-70 {
	margin-left: 70px;
}
.ml-75 {
	margin-left: 75px;
}
.ml-80 {
	margin-left: 80px;
}
.ml-85 {
	margin-left: 85px;
}
.ml-90 {
	margin-left: 90px;
}
.ml-95 {
	margin-left: 95px;
}
.ml-100 {
	margin-left: 100px;
}
.ml-105 {
	margin-left: 105px;
}
.ml-110 {
	margin-left: 110px;
}
.ml-115 {
	margin-left: 115px;
}
.ml-120 {
	margin-left: 120px;
}
.ml-125 {
	margin-left: 125px;
}
.ml-130 {
	margin-left: 130px;
}
.ml-135 {
	margin-left: 135px;
}
.ml-140 {
	margin-left: 140px;
}
.ml-145 {
	margin-left: 145px;
}
.ml-150 {
	margin-left: 150px;
}
/* margin right */
.mr-6 {
	margin-right: 6px;
}
.mr-7 {
	margin-right: 7px;
}
.mr-8 {
	margin-right: 8px;
}
.mr-9 {
	margin-right: 9px;
}
.mr-10 {
	margin-right: 10px;
}
.mr-11 {
	margin-right: 11px;
}
.mr-12 {
	margin-right: 12px;
}
.mr-13 {
	margin-right: 13px;
}
.mr-14 {
	margin-right: 14px;
}
.mr-15 {
	margin-right: 15px;
}
.mr-16 {
	margin-right: 16px;
}
.mr-17 {
	margin-right: 17px;
}
.mr-18 {
	margin-right: 18px;
}
.mr-19 {
	margin-right: 19px;
}
.mr-20 {
	margin-right: 20px;
}
.mr-21 {
	margin-right: 21px;
}
.mr-22 {
	margin-right: 22px;
}
.mr-23 {
	margin-right: 23px;
}
.mr-24 {
	margin-right: 24px;
}
.mr-25 {
	margin-right: 25px;
}
.mr-26 {
	margin-right: 26px;
}
.mr-27 {
	margin-right: 27px;
}
.mr-28 {
	margin-right: 28px;
}
.mr-29 {
	margin-right: 29px;
}
.mr-30 {
	margin-right: 30px;
}
.mr-31 {
	margin-right: 31px;
}
.mr-32 {
	margin-right: 32px;
}
.mr-33 {
	margin-right: 33px;
}
.mr-34 {
	margin-right: 34px;
}
.mr-35 {
	margin-right: 35px;
}
.mr-36 {
	margin-right: 36px;
}
.mr-37 {
	margin-right: 37px;
}
.mr-38 {
	margin-right: 38px;
}
.mr-39 {
	margin-right: 39px;
}
.mr-40 {
	margin-right: 40px;
}
.mr-45 {
	margin-right: 45px;
}
.mr-50 {
	margin-right: 50px;
}
.mr-55 {
	margin-right: 55px;
}
.mr-60 {
	margin-right: 60px;
}
.mr-65 {
	margin-right: 65px;
}
.mr-70 {
	margin-right: 70px;
}
.mr-75 {
	margin-right: 75px;
}
.mr-80 {
	margin-right: 80px;
}
.mr-85 {
	margin-right: 85px;
}
.mr-90 {
	margin-right: 90px;
}
.mr-95 {
	margin-right: 95px;
}
.mr-100 {
	margin-right: 100px;
}
.mr-105 {
	margin-right: 105px;
}
.mr-110 {
	margin-right: 110px;
}
.mr-115 {
	margin-right: 115px;
}
.mr-120 {
	margin-right: 120px;
}
.mr-125 {
	margin-right: 125px;
}
.mr-130 {
	margin-right: 130px;
}
.mr-135 {
	margin-right: 135px;
}
.mr-140 {
	margin-right: 140px;
}
.mr-145 {
	margin-right: 145px;
}
.mr-150 {
	margin-right: 150px;
}
/*--------------------------
	06. border
--------------------------*/
/* border width */
.border-width-2 {
	border-width: 2px;
}
.border-width-3 {
	border-width: 3px;
}
.border-width-4 {
	border-width: 4px;
}
.border-width-5 {
	border-width: 5px;
}
.border-width-6 {
	border-width: 6px;
}
.border-width-7 {
	border-width: 7px;
}
.border-width-8 {
	border-width: 8px;
}
.border-width-9 {
	border-width: 9px;
}
.border-width-10 {
	border-width: 10px;
}
/* border left width */
.border-left-width-2 {
	border-left-width: 2px !important;
}
.border-left-width-3 {
	border-left-width: 3px !important;
}
.border-left-width-4 {
	border-left-width: 4px !important;
}
.border-left-width-5 {
	border-left-width: 5px !important;
}
.border-left-width-6 {
	border-left-width: 6px !important;
}
.border-left-width-7 {
	border-left-width: 7px !important;
}
.border-left-width-8 {
	border-left-width: 8px !important;
}
.border-left-width-9 {
	border-left-width: 9px !important;
}
.border-left-width-10 {
	border-left-width: 10px !important;
}
/* border right width */
.border-right-width-2 {
	border-right-width: 2px;
}
.border-right-width-3 {
	border-right-width: 3px;
}
.border-right-width-4 {
	border-right-width: 4px;
}
.border-right-width-5 {
	border-right-width: 5px;
}
.border-right-width-6 {
	border-right-width: 6px;
}
.border-right-width-7 {
	border-right-width: 7px;
}
.border-right-width-8 {
	border-right-width: 8px;
}
.border-right-width-9 {
	border-right-width: 9px;
}
.border-right-width-10 {
	border-right-width: 10px;
}
/* border top width */
.border-top-width-2 {
	border-top-width: 2px;
}
.border-top-width-3 {
	border-top-width: 3px;
}
.border-top-width-4 {
	border-top-width: 4px;
}
.border-top-width-5 {
	border-top-width: 5px;
}
.border-top-width-6 {
	border-top-width: 6px;
}
.border-top-width-7 {
	border-top-width: 7px;
}
.border-top-width-8 {
	border-top-width: 8px;
}
.border-top-width-9 {
	border-top-width: 9px;
}
.border-top-width-10 {
	border-top-width: 10px;
}
/* border bottom width */
.border-bottom-width-2 {
	border-bottom-width: 2px;
}
.border-bottom-width-3 {
	border-bottom-width: 3px;
}
.border-bottom-width-4 {
	border-bottom-width: 4px;
}
.border-bottom-width-5 {
	border-bottom-width: 5px;
}
.border-bottom-width-6 {
	border-bottom-width: 6px;
}
.border-bottom-width-7 {
	border-bottom-width: 7px;
}
.border-bottom-width-8 {
	border-bottom-width: 8px;
}
.border-bottom-width-9 {
	border-bottom-width: 9px;
}
.border-bottom-width-10 {
	border-bottom-width: 10px;
}
/* border style */
.border-style-solid {
	border-style: solid;
}
.border-style-dashed {
	border-style: dashed;
}
.border-style-dotted {
	border-style: dotted;
}
.border-style-double {
	border-style: double;
}
