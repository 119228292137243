.newSiteContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: rgb(221, 221, 221);
  border-left: 1px solid rgba(0, 0, 0, 0.158);
  display: flex;
  padding: 0 5rem;
  padding-bottom: 10rem;
  flex-direction: column;
  height: 100vh;
  overflow: scroll;
}
.newSiteContainer h1 {
  color: rgba(0, 0, 0, 0.733);
  font: 300 2rem 'Roboto';
  text-align: center;
}

.createSiteForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 3vmax;
  margin-top: 50px;
  justify-content: space-evenly;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.267);
  width: 100%;
  background-color: white;
}

.createSiteForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}
.createSiteForm > div > input,
.createSiteForm > div > select,
.createSiteForm > div > textarea {
  padding: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font-size: 20px !important;
  outline: none;
}

.createSiteForm > div > svg {
  position: relative;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

#createSiteFormFile > input {
  display: flex;
  padding: 0%;
}

#createSiteFormFile > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 5vh;
  border: none;
  margin: 0%;
  font: 400 0.8vmax cursive;
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: rgb(255, 255, 255);
}

#createSiteFormFile > input::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

#createSiteFormImage {
  width: 100%;
  overflow: auto;
}

#createSiteFormImage > img {
  width: 5vmax;
  margin: 0 0.5vmax;
}
#createSiteBtn {
  border: none;
  background-color: tomato;
  color: white;
  font: 300 0.9vmax 'Roboto';
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

#createSiteBtn:hover {
  background-color: rgb(179, 66, 46);
}

@media screen and (max-width: 600px) {
  .newSiteContainer {
    background-color: rgb(255, 255, 255);
  }
  .createSiteForm {
    padding: 5vmax;
  }

  .createSiteForm > div > input,
  .createSiteForm > div > select,
  .createSiteForm > div > textarea {
    padding: 2.5vmax 5vmax;
    font-size: 10px !important;
  }

  .createSiteForm > div > svg {
    font-size: 2.8vmax;
  }

  #createSiteFormFile > img {
    width: 50px !important;
    border-radius: 100%;
  }

  #createSiteFormFile > input::file-selector-button {
    height: 7vh;
    font-size: 10px !important;
  }

  #createSiteBtn {
    font: 300 1.9vmax 'Roboto';
    padding: 1.8vmax;
  }
}
