.myQRCode{
    margin-top: 50px;
    padding-bottom: 50px;
}

.qr-code {
    background-image: url(../../../img/bg/qr-code.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    text-align: center;
    background-size: cover;
    padding: 220px 0px;
    position: relative;
    z-index: 0;
  }
  .inner-page-title-area:after {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0px;
    left: 0px;
    z-index: -1;
  }
  .inner-page-title-area h1 {
    font-weight: 400;
    font-size: 36px;
    color: #fff;
    margin-top: 83px;
    line-height: 24px;
  }
  .inner-page-title-area h1 span {
    position: relative;
  }
  .inner-page-title-area h1 span:after {
    position: absolute;
    content: '';
    width: 20px;
    height: 2px;
    background-color: #f44647;
    top: 50%;
    left: -35px;
    transform: translateY(-50%);
  }
  .inner-page-title-area h1 span:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 2px;
    background-color: #f44647;
    top: 50%;
    right: -35px;
    transform: translateY(-50%);
  }
  .inner-page-title-area .breadcrumb {
    margin: auto;
    text-align: center;
    padding: 0px;
    display: inline-block;
    background-color: transparent;
    position: absolute;
    bottom: 30px;
    left: 0px;
    right: 0px;
  }
  .inner-page-title-area .breadcrumb li {
    float: none;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
  }
  .inner-page-title-area .breadcrumb li a {
    color: #f44647;
  }
  .inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
    color: #fff;
  }
  