.serviceListContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: rgb(221, 221, 221);
  border-left: 1px solid rgba(0, 0, 0, 0.158);
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
}

.serviceListTable {
  width: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.267);
}

#serviceListHeading {
  font: 400 2rem 'Roboto';
  padding: 0.5vmax;
  box-sizing: border-box;
  transition: all 0.5s;
  margin: 2rem;
  text-align: center;
}
