@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@charset "utf-8";

* {
  margin: 0;
  scroll-behavior: smooth;
}

body {
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAABPBJREFUeJy1ln9MVWUYx5/nPfeee7jcAC94UQQcKhCaXs1htZhJ07Vk0B/KVsv1h9rKZq5FbJZbv/9wSNAkTVpjCy1AE8PNLWlINDUjIyklEUp+FonABRK4P877ds6555x7j4p6EZ673bP73PO+n+f5Ps/7nIOMDaD4U9FimHCtQVtyk9sS8bN16WYfzLCh98x788DVsh+9E2sBTL1gsv3C0FqJvPUPmpjezac+Oz4z4Pq8x2DwykH0eRciQwAKwNDiBrPtd7DYT8CspBpwpFw0pWR5pxVM246H04uVh2HMtR5ElF3AJLgcACoXHkj47AtgjioA4M9jwrJuLj134r7B8pf3+LbXYLCnmFEmOYgCVcCKAlIg0gfATIEIvWCJPMsci/ZhTPw586rcKauggMXqVx+mY4M1MPZfvMKQYMpVk57J0hD1KgfCURQiOkSz9WsS7qg3PZhxlqx8YiR08Lcf2tlAZwEdub4F1WwVqBaEGgiTfApYC4iYKBJLL3DCeeTDa4G3/QirMi+bV6x23xNYNt/RtzbS3tYjKBdYyk7LVtrdn3IQ0P8/GFRBSQVmsbUyIaKGRNjrTOlPNuLy9ElV0MHimfJosflUlyS3VautX2bUFZD9utxBfiUAuTGZ6icmEa0RnSBEfgqO2O85R3wzl5XtvS2YjVwD77HCA/hPx0vMUGNNclDBKlD0L/XfS4KCwMC9QJg0D1zMzJ8nc+KqYVFylTkre8gAls1btiuD9ffUgtsdhqqcjAYCUDZTm03/rWaMWh8oSqmByNtT5l8PhIJg7aexCYViWtLHRnDlR7Gs+8+TMOJyGqKnfol1KUV1U/0EBMlOg/1gCFhZb4u8xC1JW2sAs7ZLxFdbsZv1dOT7z6/cxeoCZQO4SQVihCuZa/Ibg9EDj46pwuSFWw1g2cYPFsdxLS2dzOM1oQ4JbIaGWmq/1SbUIMH3EE4av3wfxsQ0comJR03bn6+6pcayjTU3IFdd/QMOj2SAFr2WtZqR/4iBkrFWhkC3qycgzErJnLnHwDNRinFz23FBQh/3zDr9gXMLWDb3+zt3wMDQbvCIYfp5pTdLF+h2xcfxXiKEXWWzIurQHn2SLE07bVq/euB2+08K9pQUrYD2jnI2Pv6Qf2AEZNRgeh3N/DBERdUjzx0ilvAr6EztovPnjfKPLKeTQScFTwz3E+7dPQeoa/jF4HGp11iro2z26MO+jMwXbJsy7zom7wpW4Plv5+J1VwW4fRzTO5cZuhlNZi+LiswVPnunJhToHcGekrLF7MrVr9i/g87AAAHDyMQHbG0Qa99oKcr7bdrArKGJjFccyyPXhgr8U4jzD46gYQCJjlKyaslOy5Yc17SBZfN+UJohXmg9BW7RHGiuwIMCli3YAGuWfiNkr75jI4UO/rVVEPd8eY4NjjoxCCyTkbeA+PSjDuuOnP5QoXcFy+Z+pfh12vZ3IYoMteGgSJ/oqA07lP/UVKD3BPbsKnPSpr++ozc8s1HtbsYRkTgXbhZKtpbPGNhd1WBmlWdPQN/wOn0cCpY+cCZmCns3X54xsGwTO8peZo3tnzCf1NqSzGR+TAN5PDmHfyM7pBe80MGf10XRL05fhlFPrPKCsDJ+O/dmzn4+ZS6bUbBsYxv2lUC3axND7L1R/lyGIzUp5LM7NfDeOjs5054AlA4JR7Z13Q9Utv8B096aEKniKSgAAAAASUVORK5CYII=), auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

::selection {
  background-color: #f44647;
  color: #fff;
}
::-moz-selection {
  background-color: #f44647;
  color: #fff;
}
img {
  max-width: 100%;
}
/* body {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: #c5c5c5;
  letter-spacing: 0.5px;
  background-color: #202020;
} */

.greenColor {
  color: #008000;
}
.redColor {
  color: #ff0000;
}
.theme-bg-light {
  background-color: #1d4ddc;
}
.theme-bg-dark {
  background-color: #0e0e0e;
}
p {
  line-height: 24px;
  font-size: 14px;
  color: #6c757d;
  font-family: 'Roboto', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  color: #202020;
  padding: 0px;
  line-height: 1.2;
  letter-spacing: 1px;
  font-family: 'Roboto', sans-serif;
}
h1 {
  font-size: 60px;
  font-weight: 700;
}
h2 {
  font-size: 36px;
  font-weight: 700;
}
h3 {
  font-size: 26px;
  font-weight: 700;
}
h4 {
  font-size: 24px;
  font-weight: 700;
}
h5 {
  font-size: 18px;
  font-weight: 700;
}
h6 {
  font-size: 16px;
  font-weight: 700;
}
ul,
ol,
li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
/* --- Top Scroll --- */
.scroll-top {
  display: none;
  position: fixed;
  bottom: 2px;
  text-align: center;
  right: 2%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  color: #fff;
  background-color: #f44647;
  text-decoration: none;
  border-radius: 100%;
  z-index: 9999;
}
.scroll-top:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #d5292a;
}
/* --- Buttons --- */
.btn-style-1 {
  background-color: #f44647;
  color: #fff;
  border: 0;
  border-radius: 50px;
  padding: 12px 20px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.btn-style-1:hover {
  background-color: #d5292a;
  color: #fff;
  text-decoration: none;
}
.btn-style-2 {
  color: #fff;
  background-color: #20202090;
  border-radius: 50px;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 22px;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.btn-style-2:hover {
  background-color: #d5292a;
  color: #fff;
  text-decoration: none;
}
.btn-style-3 {
  color: #f44647;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
  letter-spacing: 1px;
  padding-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.btn-style-3:hover {
  color: #fff;
  text-decoration: none;
  letter-spacing: 2px;
}
.btn-style-4 {
  background-color: #f44647;
  color: #fff;
  border: 0;
  margin: 10px 5px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.btn-style-4:hover {
  background-color: #d5292a;
  color: #fff !important;
  text-decoration: none;
}
.btn-style-4:focus {
  background-color: #d5292a;
  color: #fff !important;
  text-decoration: none;
}
/* --- Loading --- */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  text-align: center;
  height: 100%;
  vertical-align: middle;
  height: 100%;
  overflow: visible;
  background-color: #fff;
}
#preloader .spinner-grow {
  top: 50%;
  position: relative;
}
/* --- section title --- */
.section-title h2 {
  font-weight: 600;
  font-size: 34px;
  text-transform: uppercase;
  position: relative;
  color: #fff;
}
.section-title .border-title {
  position: relative;
  height: 2px;
  background-color: #f44647;
  width: 160px;
  display: inline-block;
  border-radius: 100%;
}
.section-title .border-title:after {
  position: absolute;
  content: '';
  width: 110px;
  height: 1px;
  left: 0px;
  right: 0px;
  margin: auto;
  bottom: -3px;
  background-color: #fff;
  opacity: 0.3;
}
.section-title .border-title:before {
  position: absolute;
  content: '';
  width: 110px;
  height: 1px;
  left: 0px;
  right: 0px;
  margin: auto;
  bottom: 4px;
  background-color: #fff;
  opacity: 0.3;
}

/* Medium Devices, Desktops */
@media (max-width: 991px) {
  .btn-style-2 {
    color: #fff;
    background-color: #20202090;
    border-radius: 50px;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 22px;
    text-decoration: none;
    display: inline-block;
    transition: all 0.3s ease-in-out;
  }
  .LoginSignUpBox,
  .forgotPasswordBox,
  .updateProfileBox,
  .resetPasswordBox,
  .updatePasswordBox {
    width: 80% !important;
  }

  .createServiceForm,
  .createSiteForm {
    width: 95% !important;
  }

  .serviceListContainer,
  .siteListContainer {
    padding: 0 0.5rem !important;
  }

  .LoginSignUpContainer,
  .forgotPasswordContainer,
  .resetPasswordContainer {
    height: 100vh !important;
    margin-top: 95px !important;
  }

  .updatePasswordContainer,
  .dashboard {
    height: 100vh !important;
    margin-top: 110px !important;
  }

  .qrcode {
    display: none !important;
  }

  .profile {
    margin-left: 50px !important;
  }
  .profile h1 {
    margin-left: 200px !important;
  }

  .slider {
    margin-top: 18px !important;
  }

  .header-container {
    padding: 0 1rem !important;
  }
  .header-social {
    display: none;
  }
  .header-lover .navbar .nav-item .nav-link {
    font-size: 12px;
    padding: 20px 10px;
  }
  .slider .carousel-item {
    height: 500px;
  }
  .slider .carousel-control-next,
  .slider .carousel-control-prev {
    top: 85% !important;
    background-color: #ff0000 !important;
    display: block;
  }
  .slider .carousel-caption h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .feature-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .about-images-1,
  .about-images-3 {
    display: none;
  }
  .footer-links li a {
    padding: 0 8px;
  }
}

/* Small Devices, Tablets */
@media (max-width: 767px) {
  .serviceListContainer,
  .siteListContainer {
    padding-bottom: 100px !important;
  }

  .profile {
    margin-left: 50px !important;
  }

  .profile h1 {
    margin-left: 0px !important;
  }

  .LoginSignUpContainer,
  .forgotPasswordContainer,
  .resetPasswordContainer,
  .updatePasswordContainer,
  .newServiceContainer,
  .newSiteContainer,
  .dashboard,
  .profileContainer {
    height: 100vh !important;
    margin-top: 70px !important;
  }

  .sidebar {
    margin-left: -50px !important;
    height: 80vh !important;
  }
  .dashboard_text {
    font-size: 16px !important;
  }
  .slider {
    margin-top: -20px !important;
  }

  .navbar-brand {
    margin-left: 1rem !important;
  }
  .navbar-toggler {
    margin-right: 1rem !important;
  }
  .header-container {
    padding: 0 0 !important;
  }
  .slider{
    margin-top: 60px !important;
  }
  .slider .carousel-caption .slider-caption-box {
    position: relative !important;
    background-color: #20202090 !important;
    padding: 30px !important;
    border-radius: 10px !important;
    border: 0px !important;
  }
  .slider .carousel-item {
    background-position: center;
    background-size: contain;
    height: 60vh !important;
    width: 100%;
  }
  .slider .carousel-caption p {
    display: none;
  }
  .slider-caption-box h2 {
    font-size: 20px !important;
  }

  .profile {
    margin-left: -30px !important;
  }
  .dashboard_text {
    font-size: 16px !important;
  }

  .btn-style-4 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .slider .carousel-control-next,
  .slider .carousel-control-prev {
    top: 90% !important;
    background-color: #ff0000 !important;
    display: block;
  }
  .feature-item {
    border-bottom: 0px;
  }
  .footer-links li {
    margin: 0px 0px 10px 0px !important;
    width: 49%;
  }
  .comment-classic {
    display: inline-block;
  }
  .comment-classic > * + * {
    margin-left: 0px;
  }
}

/* Extra Small Devices, Phones */
@media (max-width: 575px) {
  .slider .carousel-caption .slider-caption-box {
    position: relative !important;
    margin-top: 80px !important;
    background-color: #20202090 !important;
    padding: 30px !important;
    border-radius: 10px !important;
    border: 0px !important;
  }
  .serviceListContainer,
  .siteListContainer {
    padding-bottom: 100px !important;
  }

  .profile {
    margin-left: 50px !important;
  }

  .profile h1 {
    margin-left: 0px !important;
  }

  .LoginSignUpContainer,
  .forgotPasswordContainer,
  .resetPasswordContainer,
  .updatePasswordContainer,
  .newServiceContainer,
  .newSiteContainer,
  .dashboard,
  .profileContainer {
    height: 100vh !important;
    margin-top: 70px !important;
  }

  .sidebar {
    margin-left: -50px !important;
    height: 80vh !important;
  }
  .dashboard_text {
    font-size: 16px !important;
  }
  .slider {
    margin-top: -20px !important;
  }

  .navbar-brand {
    margin-left: 1rem !important;
  }
  .navbar-toggler {
    margin-right: 1rem !important;
  }
  .header-container {
    padding: 0 0 !important;
  }
  .slider{
    margin-top: 60px !important;
  }
  .slider .carousel-caption .slider-caption-box {
    position: relative !important;
    background-color: #20202090 !important;
    padding: 30px !important;
    border-radius: 10px !important;
    border: 0px !important;
  }
  .slider .carousel-item {
    background-position: center;
    background-size: contain;
    height: 60vh !important;
    width: 100%;
  }
  .slider .carousel-caption p {
    display: none;
  }
  .slider-caption-box h2 {
    font-size: 20px !important;
  }

  .profile {
    margin-left: -30px !important;
  }
  .dashboard_text {
    font-size: 16px !important;
  }

  .btn-style-4 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .slider .carousel-control-next,
  .slider .carousel-control-prev {
    top: 90% !important;
    background-color: #ff0000 !important;
    display: block;
  }
  .feature-item {
    border-bottom: 0px;
  }
  .footer-links li {
    margin: 0px 0px 10px 0px !important;
    width: 49%;
  }
  .comment-classic {
    display: inline-block;
  }
  .comment-classic > * + * {
    margin-left: 0px;
  }
}

@charset "utf-8";
/* CSS Document */

/*========================================================================
	
	Table of contents
	
	01. Transition CSS
	02. Font Size
	03. Social icon color
	04. Padding
	05. Margin
	06. Border

========================================================================*/

/*--------------------------
	01. Transition CSS
--------------------------*/
.transition-1s {
	transition: all 1s ease-in-out;
}
.transition-02s {
	transition: all 0.2s ease-in-out;
}
.transition-03s {
	transition: all 0.3s ease-in-out;
}
.transition-04s {
	transition: all 0.4s ease-in-out;
}
.transition-05s {
	transition: all 0.5s ease-in-out;
}
.transition-06s {
	transition: all 0.6s ease-in-out;
}
/*--------------------------
	02. Font Size
--------------------------*/
.font-size-12 {
	font-size: 12px;
}
.font-size-13 {
	font-size: 13px;
}
.font-size-14 {
	font-size: 14px;
}
.font-size-15 {
	font-size: 15px;
}
.font-size-16 {
	font-size: 16px;
}
.font-size-17 {
	font-size: 17px;
}
.font-size-18 {
	font-size: 18px;
}
.font-size-19 {
	font-size: 19px;
}
.font-size-20 {
	font-size: 20px;
}
.font-size-21 {
	font-size: 21px;
}
.font-size-22 {
	font-size: 22px;
}
.font-size-23 {
	font-size: 23px;
}
.font-size-24 {
	font-size: 24px;
}
.font-size-25 {
	font-size: 25px;
}
.font-size-26 {
	font-size: 26px;
}
.font-size-27 {
	font-size: 27px;
}
.font-size-28 {
	font-size: 28px;
}
.font-size-29 {
	font-size: 29px;
}
.font-size-30 {
	font-size: 30px;
}
.font-size-31 {
	font-size: 31px;
}
.font-size-32 {
	font-size: 32px;
}
.font-size-33 {
	font-size: 33px;
}
.font-size-34 {
	font-size: 34px;
}
.font-size-35 {
	font-size: 35px;
}
.font-size-36 {
	font-size: 36px;
}
.font-size-37 {
	font-size: 37px;
}
.font-size-38 {
	font-size: 38px;
}
.font-size-39 {
	font-size: 39px;
}
.font-size-40 {
	font-size: 40px;
}
.font-size-41 {
	font-size: 41px;
}
.font-size-42 {
	font-size: 42px;
}
.font-size-43 {
	font-size: 43px;
}
.font-size-44 {
	font-size: 44px;
}
.font-size-45 {
	font-size: 45px;
}
.font-size-46 {
	font-size: 46px;
}
.font-size-47 {
	font-size: 47px;
}
.font-size-48 {
	font-size: 48px;
}
.font-size-49 {
	font-size: 49px;
}
.font-size-50 {
	font-size: 50px;
}
.font-size-51 {
	font-size: 51px;
}
.font-size-52 {
	font-size: 52px;
}
.font-size-53 {
	font-size: 53px;
}
.font-size-54 {
	font-size: 54px;
}
.font-size-55 {
	font-size: 55px;
}
.font-size-56 {
	font-size: 56px;
}
.font-size-57 {
	font-size: 57px;
}
.font-size-58 {
	font-size: 58px;
}
.font-size-59 {
	font-size: 59px;
}
.font-size-60 {
	font-size: 60px;
}
/*--------------------------
	03. Social icon color
--------------------------*/
/* Social color */
.facebook-color {
	color: #3a559f;
}
.twitter-color {
	color: #2aaae0;
}
.linkedin-color {
	color: #007bb5;
}
.google-plus-color {
	color: #dc4335;
}
.pinterest-color {
	color: #cb2027;
}
.whatsapp-color {
	color: #0dc143;
}
.youtube-color {
	color: #e42b33;
}
.instagram-color {
	color: #c82d9e;
}
.skype-color {
	color: #31c5ed;
}
/* Social background color */
.facebook-bg-color {
	background-color: #3a559f;
}
.twitter-bg-color {
	background-color: #2aaae0;
}
.linkedin-bg-color {
	background-color: #007bb5;
}
.google-plus-bg-color {
	background-color: #dc4335;
}
.pinterest-bg-color {
	background-color: #cb2027;
}
.whatsapp-bg-color {
	background-color: #0dc143;
}
.youtube-bg-color {
	background-color: #e42b33;
}
.instagram-bg-color {
	background-color: #c82d9e;
}
.skype-bg-color {
	background-color: #31c5ed;
}
/*--------------------------
	04. padding
--------------------------*/
/* padding all */
.p-6 {
	padding: 6px;
}
.p-7 {
	padding: 7px;
}
.p-8 {
	padding: 8px;
}
.p-9 {
	padding: 9px;
}
.p-10 {
	padding: 10px;
}
.p-11 {
	padding: 11px;
}
.p-12 {
	padding: 12px;
}
.p-13 {
	padding: 13px;
}
.p-14 {
	padding: 14px;
}
.p-15 {
	padding: 15px;
}
.p-16 {
	padding: 16px;
}
.p-17 {
	padding: 17px;
}
.p-18 {
	padding: 18px;
}
.p-19 {
	padding: 19px;
}
.p-20 {
	padding: 20px;
}
.p-21 {
	padding: 21px;
}
.p-22 {
	padding: 22px;
}
.p-23 {
	padding: 23px;
}
.p-24 {
	padding: 24px;
}
.p-25 {
	padding: 25px;
}
.p-26 {
	padding: 26px;
}
.p-27 {
	padding: 27px;
}
.p-28 {
	padding: 28px;
}
.p-29 {
	padding: 29px;
}
.p-30 {
	padding: 30px;
}
.p-31 {
	padding: 31px;
}
.p-32 {
	padding: 32px;
}
.p-33 {
	padding: 33px;
}
.p-34 {
	padding: 34px;
}
.p-35 {
	padding: 35px;
}
.p-36 {
	padding: 36px;
}
.p-37 {
	padding: 37px;
}
.p-38 {
	padding: 38px;
}
.p-39 {
	padding: 39px;
}
.p-40 {
	padding: 40px;
}
.p-45 {
	padding: 45px;
}
.p-50 {
	padding: 50px;
}
.p-55 {
	padding: 55px;
}
.p-60 {
	padding: 60px;
}
.p-65 {
	padding: 65px;
}
.p-70 {
	padding: 70px;
}
.p-75 {
	padding: 75px;
}
.p-80 {
	padding: 80px;
}
.p-85 {
	padding: 85px;
}
.p-90 {
	padding: 90px;
}
.p-95 {
	padding: 95px;
}
.p-100 {
	padding: 100px;
}
.p-105 {
	padding: 105px;
}
.p-110 {
	padding: 110px;
}
.p-115 {
	padding: 115px;
}
.p-120 {
	padding: 120px;
}
.p-125 {
	padding: 125px;
}
.p-130 {
	padding: 135px;
}
.p-135 {
	padding: 135px;
}
.p-140 {
	padding: 140px;
}
.p-145 {
	padding: 145px;
}
.p-150 {
	padding: 150px;
}
/* padding top */
.pt-6 {
	padding-top: 6px;
}
.pt-7 {
	padding-top: 7px;
}
.pt-8 {
	padding-top: 8px;
}
.pt-9 {
	padding-top: 9px;
}
.pt-10 {
	padding-top: 10px;
}
.pt-11 {
	padding-top: 11px;
}
.pt-12 {
	padding-top: 12px;
}
.pt-13 {
	padding-top: 13px;
}
.pt-14 {
	padding-top: 14px;
}
.pt-15 {
	padding-top: 15px;
}
.pt-16 {
	padding-top: 16px;
}
.pt-17 {
	padding-top: 17px;
}
.pt-18 {
	padding-top: 18px;
}
.pt-19 {
	padding-top: 19px;
}
.pt-20 {
	padding-top: 20px;
}
.pt-21 {
	padding-top: 21px;
}
.pt-22 {
	padding-top: 22px;
}
.pt-23 {
	padding-top: 23px;
}
.pt-24 {
	padding-top: 24px;
}
.pt-25 {
	padding-top: 25px;
}
.pt-26 {
	padding-top: 26px;
}
.pt-27 {
	padding-top: 27px;
}
.pt-28 {
	padding-top: 28px;
}
.pt-29 {
	padding-top: 29px;
}
.pt-30 {
	padding-top: 30px;
}
.pt-31 {
	padding-top: 31px;
}
.pt-32 {
	padding-top: 32px;
}
.pt-33 {
	padding-top: 33px;
}
.pt-34 {
	padding-top: 34px;
}
.pt-35 {
	padding-top: 35px;
}
.pt-36 {
	padding-top: 36px;
}
.pt-37 {
	padding-top: 37px;
}
.pt-38 {
	padding-top: 38px;
}
.pt-39 {
	padding-top: 39px;
}
.pt-40 {
	padding-top: 40px;
}
.pt-45 {
	padding-top: 45px;
}
.pt-50 {
	padding-top: 50px;
}
.pt-55 {
	padding-top: 55px;
}
.pt-60 {
	padding-top: 60px;
}
.pt-65 {
	padding-top: 65px;
}
.pt-70 {
	padding-top: 70px;
}
.pt-75 {
	padding-top: 75px;
}
.pt-80 {
	padding-top: 80px;
}
.pt-85 {
	padding-top: 85px;
}
.pt-90 {
	padding-top: 90px;
}
.pt-95 {
	padding-top: 95px;
}
.pt-100 {
	padding-top: 100px;
}
.pt-105 {
	padding-top: 105px;
}
.pt-110 {
	padding-top: 110px;
}
.pt-115 {
	padding-top: 115px;
}
.pt-120 {
	padding-top: 120px;
}
.pt-125 {
	padding-top: 125px;
}
.pt-130 {
	padding-top: 130px;
}
.pt-135 {
	padding-top: 135px;
}
.pt-140 {
	padding-top: 140px;
}
.pt-145 {
	padding-top: 145px;
}
.pt-150 {
	padding-top: 150px;
}
/* padding bottom */
.pb-6 {
	padding-bottom: 6px;
}
.pb-7 {
	padding-bottom: 7px;
}
.pb-8 {
	padding-bottom: 8px;
}
.pb-9 {
	padding-bottom: 9px;
}
.pb-10 {
	padding-bottom: 10px;
}
.pb-11 {
	padding-bottom: 11px;
}
.pb-12 {
	padding-bottom: 12px;
}
.pb-13 {
	padding-bottom: 13px;
}
.pb-14 {
	padding-bottom: 14px;
}
.pb-15 {
	padding-bottom: 15px;
}
.pb-16 {
	padding-bottom: 16px;
}
.pb-17 {
	padding-bottom: 17px;
}
.pb-18 {
	padding-bottom: 18px;
}
.pb-19 {
	padding-bottom: 19px;
}
.pb-20 {
	padding-bottom: 20px;
}
.pb-21 {
	padding-bottom: 21px;
}
.pb-22 {
	padding-bottom: 22px;
}
.pb-23 {
	padding-bottom: 23px;
}
.pb-24 {
	padding-bottom: 24px;
}
.pb-25 {
	padding-bottom: 25px;
}
.pb-26 {
	padding-bottom: 26px;
}
.pb-27 {
	padding-bottom: 27px;
}
.pb-28 {
	padding-bottom: 28px;
}
.pb-29 {
	padding-bottom: 29px;
}
.pb-30 {
	padding-bottom: 30px;
}
.pb-31 {
	padding-bottom: 31px;
}
.pb-32 {
	padding-bottom: 32px;
}
.pb-33 {
	padding-bottom: 33px;
}
.pb-34 {
	padding-bottom: 34px;
}
.pb-35 {
	padding-bottom: 35px;
}
.pb-36 {
	padding-bottom: 36px;
}
.pb-37 {
	padding-bottom: 37px;
}
.pb-38 {
	padding-bottom: 38px;
}
.pb-39 {
	padding-bottom: 39px;
}
.pb-40 {
	padding-bottom: 40px;
}
.pb-45 {
	padding-bottom: 45px;
}
.pb-50 {
	padding-bottom: 50px;
}
.pb-55 {
	padding-bottom: 55px;
}
.pb-60 {
	padding-bottom: 60px;
}
.pb-65 {
	padding-bottom: 65px;
}
.pb-70 {
	padding-bottom: 70px;
}
.pb-75 {
	padding-bottom: 75px;
}
.pb-80 {
	padding-bottom: 80px;
}
.pb-85 {
	padding-bottom: 85px;
}
.pb-90 {
	padding-bottom: 90px;
}
.pb-95 {
	padding-bottom: 95px;
}
.pb-100 {
	padding-bottom: 100px;
}
.pb-105 {
	padding-bottom: 105px;
}
.pb-110 {
	padding-bottom: 110px;
}
.pb-115 {
	padding-bottom: 115px;
}
.pb-120 {
	padding-bottom: 120px;
}
.pb-125 {
	padding-bottom: 125px;
}
.pb-130 {
	padding-bottom: 130px;
}
.pb-135 {
	padding-bottom: 135px;
}
.pb-140 {
	padding-bottom: 140px;
}
.pb-145 {
	padding-bottom: 145px;
}
.pb-150 {
	padding-bottom: 150px;
}
/* padding left */
.pl-6 {
	padding-left: 6px;
}
.pl-7 {
	padding-left: 7px;
}
.pl-8 {
	padding-left: 8px;
}
.pl-9 {
	padding-left: 9px;
}
.pl-10 {
	padding-left: 10px;
}
.pl-11 {
	padding-left: 11px;
}
.pl-12 {
	padding-left: 12px;
}
.pl-13 {
	padding-left: 13px;
}
.pl-14 {
	padding-left: 14px;
}
.pl-15 {
	padding-left: 15px;
}
.pl-16 {
	padding-left: 16px;
}
.pl-17 {
	padding-left: 17px;
}
.pl-18 {
	padding-left: 18px;
}
.pl-19 {
	padding-left: 19px;
}
.pl-20 {
	padding-left: 20px;
}
.pl-21 {
	padding-left: 21px;
}
.pl-22 {
	padding-left: 22px;
}
.pl-23 {
	padding-left: 23px;
}
.pl-24 {
	padding-left: 24px;
}
.pl-25 {
	padding-left: 25px;
}
.pl-26 {
	padding-left: 26px;
}
.pl-27 {
	padding-left: 27px;
}
.pl-28 {
	padding-left: 28px;
}
.pl-29 {
	padding-left: 29px;
}
.pl-30 {
	padding-left: 30px;
}
.pl-31 {
	padding-left: 31px;
}
.pl-32 {
	padding-left: 32px;
}
.pl-33 {
	padding-left: 33px;
}
.pl-34 {
	padding-left: 34px;
}
.pl-35 {
	padding-left: 35px;
}
.pl-36 {
	padding-left: 36px;
}
.pl-37 {
	padding-left: 37px;
}
.pl-38 {
	padding-left: 38px;
}
.pl-39 {
	padding-left: 39px;
}
.pl-40 {
	padding-left: 40px;
}
.pl-45 {
	padding-left: 45px;
}
.pl-50 {
	padding-left: 50px;
}
.pl-55 {
	padding-left: 55px;
}
.pl-60 {
	padding-left: 60px;
}
.pl-65 {
	padding-left: 65px;
}
.pl-70 {
	padding-left: 70px;
}
.pl-75 {
	padding-left: 75px;
}
.pl-80 {
	padding-left: 80px;
}
.pl-85 {
	padding-left: 85px;
}
.pl-90 {
	padding-left: 90px;
}
.pl-95 {
	padding-left: 95px;
}
.pl-100 {
	padding-left: 100px;
}
.pl-105 {
	padding-left: 105px;
}
.pl-110 {
	padding-left: 110px;
}
.pl-115 {
	padding-left: 115px;
}
.pl-120 {
	padding-left: 120px;
}
.pl-125 {
	padding-left: 125px;
}
.pl-130 {
	padding-left: 130px;
}
.pl-135 {
	padding-left: 135px;
}
.pl-140 {
	padding-left: 140px;
}
.pl-145 {
	padding-left: 145px;
}
.pl-150 {
	padding-left: 150px;
}
/* padding right */
.pr-6 {
	padding-right: 6px;
}
.pr-7 {
	padding-right: 7px;
}
.pr-8 {
	padding-right: 8px;
}
.pr-9 {
	padding-right: 9px;
}
.pr-10 {
	padding-right: 10px;
}
.pr-11 {
	padding-right: 11px;
}
.pr-12 {
	padding-right: 12px;
}
.pr-13 {
	padding-right: 13px;
}
.pr-14 {
	padding-right: 14px;
}
.pr-15 {
	padding-right: 15px;
}
.pr-16 {
	padding-right: 16px;
}
.pr-17 {
	padding-right: 17px;
}
.pr-18 {
	padding-right: 18px;
}
.pr-19 {
	padding-right: 19px;
}
.pr-20 {
	padding-right: 20px;
}
.pr-21 {
	padding-right: 21px;
}
.pr-22 {
	padding-right: 22px;
}
.pr-23 {
	padding-right: 23px;
}
.pr-24 {
	padding-right: 24px;
}
.pr-25 {
	padding-right: 25px;
}
.pr-26 {
	padding-right: 26px;
}
.pr-27 {
	padding-right: 27px;
}
.pr-28 {
	padding-right: 28px;
}
.pr-29 {
	padding-right: 29px;
}
.pr-30 {
	padding-right: 30px;
}
.pr-31 {
	padding-right: 31px;
}
.pr-32 {
	padding-right: 32px;
}
.pr-33 {
	padding-right: 33px;
}
.pr-34 {
	padding-right: 34px;
}
.pr-35 {
	padding-right: 35px;
}
.pr-36 {
	padding-right: 36px;
}
.pr-37 {
	padding-right: 37px;
}
.pr-38 {
	padding-right: 38px;
}
.pr-39 {
	padding-right: 39px;
}
.pr-40 {
	padding-right: 40px;
}
.pr-45 {
	padding-right: 45px;
}
.pr-50 {
	padding-right: 50px;
}
.pr-55 {
	padding-right: 55px;
}
.pr-60 {
	padding-right: 60px;
}
.pr-65 {
	padding-right: 65px;
}
.pr-70 {
	padding-right: 70px;
}
.pr-75 {
	padding-right: 75px;
}
.pr-80 {
	padding-right: 80px;
}
.pr-85 {
	padding-right: 85px;
}
.pr-90 {
	padding-right: 90px;
}
.pr-95 {
	padding-right: 95px;
}
.pr-100 {
	padding-right: 100px;
}
.pr-105 {
	padding-right: 105px;
}
.pr-110 {
	padding-right: 110px;
}
.pr-115 {
	padding-right: 115px;
}
.pr-120 {
	padding-right: 120px;
}
.pr-125 {
	padding-right: 125px;
}
.pr-130 {
	padding-right: 130px;
}
.pr-135 {
	padding-right: 135px;
}
.pr-140 {
	padding-right: 140px;
}
.pr-145 {
	padding-right: 145px;
}
.pr-150 {
	padding-right: 150px;
}
/*--------------------------
	05. margin
--------------------------*/
/* margin all */
.m-6 {
	margin: 6px;
}
.m-7 {
	margin: 7px;
}
.m-8 {
	margin: 8px;
}
.m-9 {
	margin: 9px;
}
.m-10 {
	margin: 10px;
}
.m-11 {
	margin: 11px;
}
.m-12 {
	margin: 12px;
}
.m-13 {
	margin: 13px;
}
.m-14 {
	margin: 14px;
}
.m-15 {
	margin: 15px;
}
.m-16 {
	margin: 16px;
}
.m-17 {
	margin: 17px;
}
.m-18 {
	margin: 18px;
}
.m-19 {
	margin: 19px;
}
.m-20 {
	margin: 20px;
}
.m-21 {
	margin: 21px;
}
.m-22 {
	margin: 22px;
}
.m-23 {
	margin: 23px;
}
.m-24 {
	margin: 24px;
}
.m-25 {
	margin: 25px;
}
.m-26 {
	margin: 26px;
}
.m-27 {
	margin: 27px;
}
.m-28 {
	margin: 28px;
}
.m-29 {
	margin: 29px;
}
.m-30 {
	margin: 30px;
}
.m-31 {
	margin: 31px;
}
.m-32 {
	margin: 32px;
}
.m-33 {
	margin: 33px;
}
.m-34 {
	margin: 34px;
}
.m-35 {
	margin: 35px;
}
.m-36 {
	margin: 36px;
}
.m-37 {
	margin: 37px;
}
.m-38 {
	margin: 38px;
}
.m-39 {
	margin: 39px;
}
.m-40 {
	margin: 40px;
}
.m-45 {
	margin: 45px;
}
.m-50 {
	margin: 50px;
}
.m-55 {
	margin: 55px;
}
.m-60 {
	margin: 60px;
}
.m-65 {
	margin: 65px;
}
.m-70 {
	margin: 70px;
}
.m-75 {
	margin: 75px;
}
.m-80 {
	margin: 80px;
}
.m-85 {
	margin: 85px;
}
.m-90 {
	margin: 90px;
}
.m-95 {
	margin: 95px;
}
.m-100 {
	margin: 100px;
}
.m-105 {
	margin: 105px;
}
.m-110 {
	margin: 110px;
}
.m-115 {
	margin: 115px;
}
.m-120 {
	margin: 120px;
}
.m-125 {
	margin: 125px;
}
.m-130 {
	margin: 130px;
}
.m-135 {
	margin: 135px;
}
.m-140 {
	margin: 140px;
}
.m-145 {
	margin: 145px;
}
.m-150 {
	margin: 150px;
}
/* margin top */
.mt-6 {
	margin-top: 6px;
}
.mt-7 {
	margin-top: 7px;
}
.mt-8 {
	margin-top: 8px;
}
.mt-9 {
	margin-top: 9px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-11 {
	margin-top: 11px;
}
.mt-12 {
	margin-top: 12px;
}
.mt-13 {
	margin-top: 13px;
}
.mt-14 {
	margin-top: 14px;
}
.mt-15 {
	margin-top: 15px;
}
.mt-16 {
	margin-top: 16px;
}
.mt-17 {
	margin-top: 17px;
}
.mt-18 {
	margin-top: 18px;
}
.mt-19 {
	margin-top: 19px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-21 {
	margin-top: 21px;
}
.mt-22 {
	margin-top: 22px;
}
.mt-23 {
	margin-top: 23px;
}
.mt-24 {
	margin-top: 24px;
}
.mt-25 {
	margin-top: 25px;
}
.mt-26 {
	margin-top: 26px;
}
.mt-27 {
	margin-top: 27px;
}
.mt-28 {
	margin-top: 28px;
}
.mt-29 {
	margin-top: 29px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-31 {
	margin-top: 31px;
}
.mt-32 {
	margin-top: 32px;
}
.mt-33 {
	margin-top: 33px;
}
.mt-34 {
	margin-top: 34px;
}
.mt-35 {
	margin-top: 35px;
}
.mt-36 {
	margin-top: 36px;
}
.mt-37 {
	margin-top: 37px;
}
.mt-38 {
	margin-top: 38px;
}
.mt-39 {
	margin-top: 39px;
}
.mt-40 {
	margin-top: 40px;
}
.mt-45 {
	margin-top: 45px;
}
.mt-50 {
	margin-top: 50px;
}
.mt-55 {
	margin-top: 55px;
}
.mt-60 {
	margin-top: 60px;
}
.mt-65 {
	margin-top: 65px;
}
.mt-70 {
	margin-top: 70px;
}
.mt-75 {
	margin-top: 75px;
}
.mt-80 {
	margin-top: 80px;
}
.mt-85 {
	margin-top: 85px;
}
.mt-90 {
	margin-top: 90px;
}
.mt-95 {
	margin-top: 95px;
}
.mt-100 {
	margin-top: 100px;
}
.mt-105 {
	margin-top: 105px;
}
.mt-110 {
	margin-top: 110px;
}
.mt-115 {
	margin-top: 115px;
}
.mt-120 {
	margin-top: 120px;
}
.mt-125 {
	margin-top: 125px;
}
.mt-130 {
	margin-top: 130px;
}
.mt-135 {
	margin-top: 135px;
}
.mt-140 {
	margin-top: 140px;
}
.mt-145 {
	margin-top: 145px;
}
.mt-150 {
	margin-top: 150px;
}
/* margin bottom */
.mb-6 {
	margin-bottom: 6px;
}
.mb-7 {
	margin-bottom: 7px;
}
.mb-8 {
	margin-bottom: 8px;
}
.mb-9 {
	margin-bottom: 9px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-11 {
	margin-bottom: 11px;
}
.mb-12 {
	margin-bottom: 12px;
}
.mb-13 {
	margin-bottom: 13px;
}
.mb-14 {
	margin-bottom: 14px;
}
.mb-15 {
	margin-bottom: 15px;
}
.mb-16 {
	margin-bottom: 16px;
}
.mb-17 {
	margin-bottom: 17px;
}
.mb-18 {
	margin-bottom: 18px;
}
.mb-19 {
	margin-bottom: 19px;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-21 {
	margin-bottom: 21px;
}
.mb-22 {
	margin-bottom: 22px;
}
.mb-23 {
	margin-bottom: 23px;
}
.mb-24 {
	margin-bottom: 24px;
}
.mb-25 {
	margin-bottom: 25px;
}
.mb-26 {
	margin-bottom: 26px;
}
.mb-27 {
	margin-bottom: 27px;
}
.mb-28 {
	margin-bottom: 28px;
}
.mb-29 {
	margin-bottom: 29px;
}
.mb-30 {
	margin-bottom: 30px;
}
.mb-31 {
	margin-bottom: 31px;
}
.mb-32 {
	margin-bottom: 32px;
}
.mb-33 {
	margin-bottom: 33px;
}
.mb-34 {
	margin-bottom: 34px;
}
.mb-35 {
	margin-bottom: 35px;
}
.mb-36 {
	margin-bottom: 36px;
}
.mb-37 {
	margin-bottom: 37px;
}
.mb-38 {
	margin-bottom: 38px;
}
.mb-39 {
	margin-bottom: 39px;
}
.mb-40 {
	margin-bottom: 40px;
}
.mb-45 {
	margin-bottom: 45px;
}
.mb-50 {
	margin-bottom: 50px;
}
.mb-55 {
	margin-bottom: 55px;
}
.mb-60 {
	margin-bottom: 60px;
}
.mb-65 {
	margin-bottom: 65px;
}
.mb-70 {
	margin-bottom: 70px;
}
.mb-75 {
	margin-bottom: 75px;
}
.mb-80 {
	margin-bottom: 80px;
}
.mb-85 {
	margin-bottom: 85px;
}
.mb-90 {
	margin-bottom: 90px;
}
.mb-95 {
	margin-bottom: 95px;
}
.mb-100 {
	margin-bottom: 100px;
}
.mb-105 {
	margin-bottom: 105px;
}
.mb-110 {
	margin-bottom: 110px;
}
.mb-115 {
	margin-bottom: 115px;
}
.mb-120 {
	margin-bottom: 120px;
}
.mb-125 {
	margin-bottom: 125px;
}
.mb-130 {
	margin-bottom: 130px;
}
.mb-135 {
	margin-bottom: 135px;
}
.mb-140 {
	margin-bottom: 140px;
}
.mb-145 {
	margin-bottom: 145px;
}
.mb-150 {
	margin-bottom: 150px;
}
/* margin left */
.ml-6 {
	margin-left: 6px;
}
.ml-7 {
	margin-left: 7px;
}
.ml-8 {
	margin-left: 8px;
}
.ml-9 {
	margin-left: 9px;
}
.ml-10 {
	margin-left: 10px;
}
.ml-11 {
	margin-left: 11px;
}
.ml-12 {
	margin-left: 12px;
}
.ml-13 {
	margin-left: 13px;
}
.ml-14 {
	margin-left: 14px;
}
.ml-15 {
	margin-left: 15px;
}
.ml-16 {
	margin-left: 16px;
}
.ml-17 {
	margin-left: 17px;
}
.ml-18 {
	margin-left: 18px;
}
.ml-19 {
	margin-left: 19px;
}
.ml-20 {
	margin-left: 20px;
}
.ml-21 {
	margin-left: 21px;
}
.ml-22 {
	margin-left: 22px;
}
.ml-23 {
	margin-left: 23px;
}
.ml-24 {
	margin-left: 24px;
}
.ml-25 {
	margin-left: 25px;
}
.ml-26 {
	margin-left: 26px;
}
.ml-27 {
	margin-left: 27px;
}
.ml-28 {
	margin-left: 28px;
}
.ml-29 {
	margin-left: 29px;
}
.ml-30 {
	margin-left: 30px;
}
.ml-31 {
	margin-left: 31px;
}
.ml-32 {
	margin-left: 32px;
}
.ml-33 {
	margin-left: 33px;
}
.ml-34 {
	margin-left: 34px;
}
.ml-35 {
	margin-left: 35px;
}
.ml-36 {
	margin-left: 36px;
}
.ml-37 {
	margin-left: 37px;
}
.ml-38 {
	margin-left: 38px;
}
.ml-39 {
	margin-left: 39px;
}
.ml-40 {
	margin-left: 40px;
}
.ml-45 {
	margin-left: 45px;
}
.ml-50 {
	margin-left: 50px;
}
.ml-55 {
	margin-left: 55px;
}
.ml-60 {
	margin-left: 60px;
}
.ml-65 {
	margin-left: 65px;
}
.ml-70 {
	margin-left: 70px;
}
.ml-75 {
	margin-left: 75px;
}
.ml-80 {
	margin-left: 80px;
}
.ml-85 {
	margin-left: 85px;
}
.ml-90 {
	margin-left: 90px;
}
.ml-95 {
	margin-left: 95px;
}
.ml-100 {
	margin-left: 100px;
}
.ml-105 {
	margin-left: 105px;
}
.ml-110 {
	margin-left: 110px;
}
.ml-115 {
	margin-left: 115px;
}
.ml-120 {
	margin-left: 120px;
}
.ml-125 {
	margin-left: 125px;
}
.ml-130 {
	margin-left: 130px;
}
.ml-135 {
	margin-left: 135px;
}
.ml-140 {
	margin-left: 140px;
}
.ml-145 {
	margin-left: 145px;
}
.ml-150 {
	margin-left: 150px;
}
/* margin right */
.mr-6 {
	margin-right: 6px;
}
.mr-7 {
	margin-right: 7px;
}
.mr-8 {
	margin-right: 8px;
}
.mr-9 {
	margin-right: 9px;
}
.mr-10 {
	margin-right: 10px;
}
.mr-11 {
	margin-right: 11px;
}
.mr-12 {
	margin-right: 12px;
}
.mr-13 {
	margin-right: 13px;
}
.mr-14 {
	margin-right: 14px;
}
.mr-15 {
	margin-right: 15px;
}
.mr-16 {
	margin-right: 16px;
}
.mr-17 {
	margin-right: 17px;
}
.mr-18 {
	margin-right: 18px;
}
.mr-19 {
	margin-right: 19px;
}
.mr-20 {
	margin-right: 20px;
}
.mr-21 {
	margin-right: 21px;
}
.mr-22 {
	margin-right: 22px;
}
.mr-23 {
	margin-right: 23px;
}
.mr-24 {
	margin-right: 24px;
}
.mr-25 {
	margin-right: 25px;
}
.mr-26 {
	margin-right: 26px;
}
.mr-27 {
	margin-right: 27px;
}
.mr-28 {
	margin-right: 28px;
}
.mr-29 {
	margin-right: 29px;
}
.mr-30 {
	margin-right: 30px;
}
.mr-31 {
	margin-right: 31px;
}
.mr-32 {
	margin-right: 32px;
}
.mr-33 {
	margin-right: 33px;
}
.mr-34 {
	margin-right: 34px;
}
.mr-35 {
	margin-right: 35px;
}
.mr-36 {
	margin-right: 36px;
}
.mr-37 {
	margin-right: 37px;
}
.mr-38 {
	margin-right: 38px;
}
.mr-39 {
	margin-right: 39px;
}
.mr-40 {
	margin-right: 40px;
}
.mr-45 {
	margin-right: 45px;
}
.mr-50 {
	margin-right: 50px;
}
.mr-55 {
	margin-right: 55px;
}
.mr-60 {
	margin-right: 60px;
}
.mr-65 {
	margin-right: 65px;
}
.mr-70 {
	margin-right: 70px;
}
.mr-75 {
	margin-right: 75px;
}
.mr-80 {
	margin-right: 80px;
}
.mr-85 {
	margin-right: 85px;
}
.mr-90 {
	margin-right: 90px;
}
.mr-95 {
	margin-right: 95px;
}
.mr-100 {
	margin-right: 100px;
}
.mr-105 {
	margin-right: 105px;
}
.mr-110 {
	margin-right: 110px;
}
.mr-115 {
	margin-right: 115px;
}
.mr-120 {
	margin-right: 120px;
}
.mr-125 {
	margin-right: 125px;
}
.mr-130 {
	margin-right: 130px;
}
.mr-135 {
	margin-right: 135px;
}
.mr-140 {
	margin-right: 140px;
}
.mr-145 {
	margin-right: 145px;
}
.mr-150 {
	margin-right: 150px;
}
/*--------------------------
	06. border
--------------------------*/
/* border width */
.border-width-2 {
	border-width: 2px;
}
.border-width-3 {
	border-width: 3px;
}
.border-width-4 {
	border-width: 4px;
}
.border-width-5 {
	border-width: 5px;
}
.border-width-6 {
	border-width: 6px;
}
.border-width-7 {
	border-width: 7px;
}
.border-width-8 {
	border-width: 8px;
}
.border-width-9 {
	border-width: 9px;
}
.border-width-10 {
	border-width: 10px;
}
/* border left width */
.border-left-width-2 {
	border-left-width: 2px !important;
}
.border-left-width-3 {
	border-left-width: 3px !important;
}
.border-left-width-4 {
	border-left-width: 4px !important;
}
.border-left-width-5 {
	border-left-width: 5px !important;
}
.border-left-width-6 {
	border-left-width: 6px !important;
}
.border-left-width-7 {
	border-left-width: 7px !important;
}
.border-left-width-8 {
	border-left-width: 8px !important;
}
.border-left-width-9 {
	border-left-width: 9px !important;
}
.border-left-width-10 {
	border-left-width: 10px !important;
}
/* border right width */
.border-right-width-2 {
	border-right-width: 2px;
}
.border-right-width-3 {
	border-right-width: 3px;
}
.border-right-width-4 {
	border-right-width: 4px;
}
.border-right-width-5 {
	border-right-width: 5px;
}
.border-right-width-6 {
	border-right-width: 6px;
}
.border-right-width-7 {
	border-right-width: 7px;
}
.border-right-width-8 {
	border-right-width: 8px;
}
.border-right-width-9 {
	border-right-width: 9px;
}
.border-right-width-10 {
	border-right-width: 10px;
}
/* border top width */
.border-top-width-2 {
	border-top-width: 2px;
}
.border-top-width-3 {
	border-top-width: 3px;
}
.border-top-width-4 {
	border-top-width: 4px;
}
.border-top-width-5 {
	border-top-width: 5px;
}
.border-top-width-6 {
	border-top-width: 6px;
}
.border-top-width-7 {
	border-top-width: 7px;
}
.border-top-width-8 {
	border-top-width: 8px;
}
.border-top-width-9 {
	border-top-width: 9px;
}
.border-top-width-10 {
	border-top-width: 10px;
}
/* border bottom width */
.border-bottom-width-2 {
	border-bottom-width: 2px;
}
.border-bottom-width-3 {
	border-bottom-width: 3px;
}
.border-bottom-width-4 {
	border-bottom-width: 4px;
}
.border-bottom-width-5 {
	border-bottom-width: 5px;
}
.border-bottom-width-6 {
	border-bottom-width: 6px;
}
.border-bottom-width-7 {
	border-bottom-width: 7px;
}
.border-bottom-width-8 {
	border-bottom-width: 8px;
}
.border-bottom-width-9 {
	border-bottom-width: 9px;
}
.border-bottom-width-10 {
	border-bottom-width: 10px;
}
/* border style */
.border-style-solid {
	border-style: solid;
}
.border-style-dashed {
	border-style: dashed;
}
.border-style-dotted {
	border-style: dotted;
}
.border-style-double {
	border-style: double;
}

/* ------ venobox.css --------*/
.vbox-overlay *, .vbox-overlay *:before, .vbox-overlay *:after {
	-webkit-backface-visibility: hidden;
	box-sizing: border-box;
}
.vbox-overlay {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1040;
	-webkit-transform: translateZ(1000px);
	transform: translateZ(1000px);
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d;
}
/* ----- navigation ----- */
.vbox-title {
	width: 100%;
	height: 40px;
	float: left;
	text-align: center;
	line-height: 28px;
	font-size: 12px;
	padding: 6px 40px;
	overflow: hidden;
	position: fixed;
	display: none;
	left: 0;
	z-index: 1050;
}
.vbox-close {
	cursor: pointer;
	position: fixed;
	top: -1px;
	right: 0;
	width: 50px;
	height: 40px;
	padding: 6px;
	display: block;
	background-position: 10px center;
	overflow: hidden;
	font-size: 24px;
	line-height: 1;
	text-align: center;
	z-index: 1050;
}
.vbox-num {
	cursor: pointer;
	position: fixed;
	left: 0;
	height: 40px;
	display: block;
	overflow: hidden;
	line-height: 28px;
	font-size: 12px;
	padding: 6px 10px;
	display: none;
	z-index: 1050;
}
/* ----- navigation ARROWS ----- */
.vbox-next, .vbox-prev {
	position: fixed;
	top: 50%;
	margin-top: -15px;
	overflow: hidden;
	cursor: pointer;
	display: block;
	width: 45px;
	height: 45px;
	z-index: 1050;
}
.vbox-next span, .vbox-prev span {
	position: relative;
	width: 20px;
	height: 20px;
	border: 2px solid transparent;
	border-top-color: #B6B6B6;
	border-right-color: #B6B6B6;
	text-indent: -100px;
	position: absolute;
	top: 8px;
	display: block;
}
.vbox-prev {
	left: 15px;
}
.vbox-next {
	right: 15px;
}
.vbox-prev span {
	left: 10px;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.vbox-next span {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	right: 10px;
}
/* ------- inline window ------ */
.vbox-inline {
	width: 420px;
	height: 315px;
	height: 70vh;
	padding: 10px;
	background: #fff;
	margin: 0 auto;
	overflow: auto;
	text-align: left;
}
/* ------- Video & iFrames window ------ */
.venoframe {
	max-width: 100%;
	width: 100%;
	border: none;
	width: 100%;
	height: 260px;
	height: 70vh;
}
.venoframe.vbvid {
	height: 260px;
}
@media (min-width: 768px) {
.venoframe, .vbox-inline {
	width: 90%;
	height: 360px;
	height: 70vh;
}
.venoframe.vbvid {
	width: 640px;
	height: 360px;
}
}
@media (min-width: 992px) {
.venoframe, .vbox-inline {
	max-width: 1200px;
	width: 80%;
	height: 540px;
	height: 70vh;
}
.venoframe.vbvid {
	width: 960px;
	height: 540px;
}
}
/* 
Please do NOT edit this part! 
or at least read this note: http://i.imgur.com/7C0ws9e.gif
*/
.vbox-open {
	overflow: hidden;
}
.vbox-container {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: scroll;
	z-index: 20;
	max-height: 100%;
}
.vbox-content {
	text-align: center;
	float: left;
	width: 100%;
	position: relative;
	overflow: hidden;
	padding: 20px 10px;
}
.vbox-container img {
	max-width: 100%;
	height: auto;
}
.figlio {
	box-shadow: 0 0 12px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	max-width: 100%;
	text-align: initial;
}
img.figlio {
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.vbox-content.swipe-left {
	margin-left: -200px !important;
}
.vbox-content.swipe-right {
	margin-left: 200px !important;
}
.animated {
	transition: margin 300ms ease-out;
}
.animate-in {
	opacity: 1;
}
.animate-out {
	opacity: 0;
}
/* ---------- preloader ----------
 * SPINKIT 
 * http://tobiasahlin.com/spinkit/
-------------------------------- */
.sk-double-bounce, .sk-rotating-plane {
	width: 40px;
	height: 40px;
	margin: 40px auto
}
.sk-rotating-plane {
	background-color: #333;
	-webkit-backface-visibility: visible;
	backface-visibility: visible;
	-webkit-animation: sk-rotatePlane 1.2s infinite ease-in-out;
	animation: sk-rotatePlane 1.2s infinite ease-in-out
}
@-webkit-keyframes sk-rotatePlane {
0% {
-webkit-transform:perspective(120px) rotateX(0) rotateY(0);
transform:perspective(120px) rotateX(0) rotateY(0)
}
50% {
-webkit-transform:perspective(120px) rotateX(-180.1deg) rotateY(0);
transform:perspective(120px) rotateX(-180.1deg) rotateY(0)
}
100% {
-webkit-transform:perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
transform:perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
}
}
@keyframes sk-rotatePlane {
0% {
-webkit-transform:perspective(120px) rotateX(0) rotateY(0);
transform:perspective(120px) rotateX(0) rotateY(0)
}
50% {
-webkit-transform:perspective(120px) rotateX(-180.1deg) rotateY(0);
transform:perspective(120px) rotateX(-180.1deg) rotateY(0)
}
100% {
-webkit-transform:perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
transform:perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
}
}
.sk-double-bounce {
	position: relative
}
.sk-double-bounce .sk-child {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #333;
	opacity: .6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-doubleBounce 2s infinite ease-in-out;
	animation: sk-doubleBounce 2s infinite ease-in-out
}
.sk-double-bounce .sk-double-bounce2 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s
}
@-webkit-keyframes sk-doubleBounce {
0%, 100% {
-webkit-transform:scale(0);
transform:scale(0)
}
50% {
-webkit-transform:scale(1);
transform:scale(1)
}
}
@keyframes sk-doubleBounce {
0%, 100% {
-webkit-transform:scale(0);
transform:scale(0)
}
50% {
-webkit-transform:scale(1);
transform:scale(1)
}
}
.sk-wave {
	width: 50px;
	height: 40px;
	text-align: center;
	font-size: 10px;
	margin: 40px auto
}
.sk-wave .sk-rect {
	background-color: #333;
	height: 100%;
	width: 4px;
	margin: 0 1px;
	display: inline-block;
	-webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
	animation: sk-waveStretchDelay 1.2s infinite ease-in-out
}
.sk-wave .sk-rect1 {
	-webkit-animation-delay: -1.2s;
	animation-delay: -1.2s
}
.sk-wave .sk-rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s
}
.sk-wave .sk-rect3 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s
}
.sk-wave .sk-rect4 {
	-webkit-animation-delay: -.9s;
	animation-delay: -.9s
}
.sk-wave .sk-rect5 {
	-webkit-animation-delay: -.8s;
	animation-delay: -.8s
}
@-webkit-keyframes sk-waveStretchDelay {
0%, 100%, 40% {
-webkit-transform:scaleY(.4);
transform:scaleY(.4)
}
20% {
-webkit-transform:scaleY(1);
transform:scaleY(1)
}
}
@keyframes sk-waveStretchDelay {
0%, 100%, 40% {
-webkit-transform:scaleY(.4);
transform:scaleY(.4)
}
20% {
-webkit-transform:scaleY(1);
transform:scaleY(1)
}
}
.sk-three-bounce {
	margin: 40px auto;
	width: 100px;
	text-align: center
}
.sk-three-bounce .sk-child {
	width: 16px;
	height: 16px;
	background-color: #333;
	border-radius: 100%;
	margin: 4px;
	display: inline-block;
	-webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
	animation: sk-three-bounce 1.4s ease-in-out 0s infinite both
}
.sk-cube-grid, .sk-spinner-pulse {
	width: 40px;
	height: 40px;
	margin: 40px auto
}
.sk-three-bounce .sk-bounce1 {
	-webkit-animation-delay: -.32s;
	animation-delay: -.32s
}
.sk-three-bounce .sk-bounce2 {
	-webkit-animation-delay: -.16s;
	animation-delay: -.16s
}
@-webkit-keyframes sk-three-bounce {
0%, 100%, 80% {
-webkit-transform:scale(0);
transform:scale(0)
}
40% {
-webkit-transform:scale(1);
transform:scale(1)
}
}
@keyframes sk-three-bounce {
0%, 100%, 80% {
-webkit-transform:scale(0);
transform:scale(0)
}
40% {
-webkit-transform:scale(1);
transform:scale(1)
}
}
.sk-spinner-pulse {
	background-color: #333;
	border-radius: 100%;
	-webkit-animation: sk-pulseScaleOut 1s infinite ease-in-out;
	animation: sk-pulseScaleOut 1s infinite ease-in-out
}
@-webkit-keyframes sk-pulseScaleOut {
0% {
-webkit-transform:scale(0);
transform:scale(0)
}
100% {
-webkit-transform:scale(1);
transform:scale(1);
opacity:0
}
}
@keyframes sk-pulseScaleOut {
0% {
-webkit-transform:scale(0);
transform:scale(0)
}
100% {
-webkit-transform:scale(1);
transform:scale(1);
opacity:0
}
}
.sk-cube-grid .sk-cube {
	width: 33.33%;
	height: 33.33%;
	background-color: #333;
	float: left;
	-webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
	animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out
}
.sk-cube-grid .sk-cube1 {
	-webkit-animation-delay: .2s;
	animation-delay: .2s
}
.sk-cube-grid .sk-cube2 {
	-webkit-animation-delay: .3s;
	animation-delay: .3s
}
.sk-cube-grid .sk-cube3 {
	-webkit-animation-delay: .4s;
	animation-delay: .4s
}
.sk-cube-grid .sk-cube4 {
	-webkit-animation-delay: .1s;
	animation-delay: .1s
}
.sk-cube-grid .sk-cube5 {
	-webkit-animation-delay: .2s;
	animation-delay: .2s
}
.sk-cube-grid .sk-cube6 {
	-webkit-animation-delay: .3s;
	animation-delay: .3s
}
.sk-cube-grid .sk-cube7 {
	-webkit-animation-delay: 0ms;
	animation-delay: 0ms
}
.sk-cube-grid .sk-cube8 {
	-webkit-animation-delay: .1s;
	animation-delay: .1s
}
.sk-cube-grid .sk-cube9 {
	-webkit-animation-delay: .2s;
	animation-delay: .2s
}
@-webkit-keyframes sk-cubeGridScaleDelay {
0%, 100%, 70% {
-webkit-transform:scale3D(1, 1, 1);
transform:scale3D(1, 1, 1)
}
35% {
-webkit-transform:scale3D(0, 0, 1);
transform:scale3D(0, 0, 1)
}
}
@keyframes sk-cubeGridScaleDelay {
0%, 100%, 70% {
-webkit-transform:scale3D(1, 1, 1);
transform:scale3D(1, 1, 1)
}
35% {
-webkit-transform:scale3D(0, 0, 1);
transform:scale3D(0, 0, 1)
}
}
.sk-wandering-cubes {
	margin: 40px auto;
	width: 40px;
	height: 40px;
	position: relative
}
.sk-wandering-cubes .sk-cube {
	background-color: #333;
	width: 10px;
	height: 10px;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both;
	animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both
}
.sk-wandering-cubes .sk-cube2 {
	-webkit-animation-delay: -.9s;
	animation-delay: -.9s
}
@-webkit-keyframes sk-wanderingCube {
0% {
-webkit-transform:rotate(0);
transform:rotate(0)
}
25% {
-webkit-transform:translateX(30px) rotate(-90deg) scale(.5);
transform:translateX(30px) rotate(-90deg) scale(.5)
}
50% {
-webkit-transform:translateX(30px) translateY(30px) rotate(-179deg);
transform:translateX(30px) translateY(30px) rotate(-179deg)
}
50.1% {
-webkit-transform:translateX(30px) translateY(30px) rotate(-180deg);
transform:translateX(30px) translateY(30px) rotate(-180deg)
}
75% {
-webkit-transform:translateX(0) translateY(30px) rotate(-270deg) scale(.5);
transform:translateX(0) translateY(30px) rotate(-270deg) scale(.5)
}
100% {
-webkit-transform:rotate(-360deg);
transform:rotate(-360deg)
}
}
@keyframes sk-wanderingCube {
0% {
-webkit-transform:rotate(0);
transform:rotate(0)
}
25% {
-webkit-transform:translateX(30px) rotate(-90deg) scale(.5);
transform:translateX(30px) rotate(-90deg) scale(.5)
}
50% {
-webkit-transform:translateX(30px) translateY(30px) rotate(-179deg);
transform:translateX(30px) translateY(30px) rotate(-179deg)
}
50.1% {
-webkit-transform:translateX(30px) translateY(30px) rotate(-180deg);
transform:translateX(30px) translateY(30px) rotate(-180deg)
}
75% {
-webkit-transform:translateX(0) translateY(30px) rotate(-270deg) scale(.5);
transform:translateX(0) translateY(30px) rotate(-270deg) scale(.5)
}
100% {
-webkit-transform:rotate(-360deg);
transform:rotate(-360deg)
}
}

/* footer links */
.footer-links li {
	display: inline-block;
}
.footer-links li a {
	padding: 0 12px;
	font-size: 12px;
	color: #6c757d;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.3s ease-in-out;
}
.footer-links li:first-child a {
	padding-left: 0px;
}
.footer-links li a:hover {
	color: #f44647;
}
/* footer copyright */
.footer-copyright {
	color: #6c757d;
	font-size: 12px;
}
/* ===================================
	Slider
=================================== */
.slider .carousel-caption {
  top: 50%;
  bottom: auto;
  padding-top: 0px;
  left: 0px;
  right: 0px;
  padding-bottom: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: left;
}
.slider .carousel-item {
  background-position: center center;
  -webkit-background-size: cover;
  margin-top: 0px;
  background-size: cover;
}
.slider .carousel-item.slider-one {
  background-image: url(/static/media/slider-1.53f779e6.jpg); /*edit image*/
  height: 100vh;
}
.slider .carousel-item.slider-two {
  background-image: url(/static/media/slider-2.73975eae.jpg); /*edit image*/
  height: 100vh;
}
.slider .carousel-item.slider-three {
  background-image: url(/static/media/slider-4.9a5d8960.jpg); /*edit image*/
  height: 100vh;
}
.slider .carousel-caption .slider-caption-box {
  position: relative;
  background-color: rgba(244, 70, 71, 0.9);
  padding: 30px;
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.4);
}
.slider .carousel-caption h3 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 400;
  text-transform: capitalize;
}
.slider .carousel-caption h2 {
  font-size: 42px;
  color: #fff;
  font-weight: 700;
  line-height: 45px;
  text-transform: uppercase;
}
.slider .carousel-caption p {
  font-size: 15px;
  font-style: italic;
  line-height: 26px;
  margin-bottom: 20px;
  color: #fff;
}
.slider .carousel-control-next,
.slider .carousel-control-prev {
  border-radius: 100%;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
}
.slider .carousel-control-next span,
.slider .carousel-control-prev span {
  width: 12px;
  height: 12px;
}
.slider .carousel-control-next {
  bottom: auto;
  top: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  right: 30px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.slider .carousel-control-prev {
  bottom: auto;
  top: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  left: 30px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* ===================================
	Service
=================================== */
.service-item {
  text-align: center;
}
.service-item .ser-img img {
  border-radius: 6px;
}
.service-item .description {
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  position: relative;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.service-item .description:before {
  width: 20px;
  position: absolute;
  content: '';
  height: 20px;
  margin: auto;
  left: 0px;
  right: 0px;
  top: -10px;
  z-index: -1;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: rgba(255, 255, 255, 0.3);
  margin: auto;
  transition: all 0.3s ease-in-out;
}
.service-item h6 {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 10px;
}
.service-item:hover .description {
  border: 1px solid #f44647;
}
.service-item:hover .description:before {
  background-color: #f44647;
}
/* sidebar */
.sidebar-item-box {
  border-radius: 6px;
  overflow: hidden;
}
/* sidebar header */
.sidebar-header h5 {
  font-size: 18px;
  font-weight: 600;
  background-color: #f44647;
  padding: 20px 20px;
  margin-bottom: 0;
  color: #fff;
  text-transform: uppercase;
}
/* sidebar list */
.sidebar-list li {
  padding: 20px;
  background-color: #0e0e0e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar-list li a {
  display: flex;
  font-size: 14px;
  width: 100%;
  align-items: center;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.sidebar-list li:last-child {
  border-bottom: none;
}
.sidebar-list li i {
  margin-left: auto;
}
.sidebar-list li a:hover {
  color: #f44647;
}
/* sidebar contact */
.sidebar-contact {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.sidebar-contact li {
  padding: 20px;
  background-color: #0e0e0e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar-contact li a {
  display: flex;
  font-size: 14px;
  width: 100%;
  align-items: center;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.sidebar-contact li:last-child {
  border-bottom: none;
}
.sidebar-contact li a:hover {
  color: #f44647;
}
.sidebar-contact span {
  margin-left: auto;
  font-weight: 400;
  font-size: 14px;
  color: #8f8f8f;
}
/* download brochure */
.download-brochure a {
  width: 100%;
  display: inline-block;
  background-color: #f44647;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 25px;
  transition: all 0.3s ease-in-out;
}
.download-brochure a i {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  padding-right: 10px;
  margin-right: 5px;
  font-size: 18px;
}
.download-brochure a:hover {
  background-color: #d5292a;
}
/* service detail img */
.service-detail-img img {
  border-radius: 6px;
}

/* ===================================
	Call to action
=================================== */
.call-to-action-area {
  background-image: url(/static/media/call-to-action-bg.5193df97.jpg);
  background-size: cover;
  background-position: center top;
  position: relative;
  z-index: 0;
}
.call-to-action-area:after {
  position: absolute;
  content: '';
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
}
.cta-content h3 {
  font-size: 18px;
  color: #f44647;
  font-weight: 400;
  margin-bottom: 20px;
}
.call-to-action-area h2 {
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  margin-bottom: 20px;
}
.call-to-action-area h2 a {
  color: #fff;
  text-decoration: underline;
}
.call-to-action-area p {
  color: #fff;
  line-height: 28px;
}
.video-popup {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  color: #fff;
  font-size: 24px;
  border-radius: 100%;
  background-color: #f44647;
}
.video-popup:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #f44647;
  border-radius: 100%;
  top: 0;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: witr_squares 1.5s ease-in-out infinite;
          animation: witr_squares 1.5s ease-in-out infinite;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.video-popup:after {
  position: absolute;
  content: '';
  width: 90px;
  height: 90px;
  border: 2px solid #f44647;
  border-radius: 100%;
  top: -5px;
  left: -5px;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.video-popup i {
  z-index: 2;
  position: relative;
}
.video-popup:hover {
  color: #fff;
}
/* witr_squares_ */
@-webkit-keyframes witr_squares {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  20% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes witr_squares {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  20% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
}

/* .banner {
  background-image: url("../../images/cover.jfif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vmin;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.banner > h1 {
  margin: 5vmax;

  font: 600 2.5vmax "Roboto";
}

.banner > p {
  font: 300 1.4vmax "Lucida Sans";
}

.banner > a > button {
  margin-bottom: 5vmax;
  cursor: pointer;
  background-color: white;
  border: 1px solid white;
  border-radius: 0;
  padding: 1vmax;
  transition: all 0.5s;
  width: 9vmax;
  font: 500 1vmax "Roboto";
}
.banner > a > button:hover {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}

.banner::after {
  content: "";
  width: 100vw;
  height: 100vmin;
  background-color: #ffffff;
  position: absolute;
  top: 0%;
  left: 0;
  clip-path: polygon(100% 68%, 0 100%, 100% 100%);
  max-width: 100%;
}

.homeHeading {
  text-align: center;
  font-family: Roboto;
  font-size: 1.4vmax;
  border-bottom: 1px solid rgba(21, 21, 21, 0.5);
  width: 20vmax;
  padding: 1vmax;
  margin: 5vmax auto;
  color: rgb(0, 0, 0, 0.7);
}

.container {
  display: flex;
  margin: 2vmax auto;
  width: 80vw;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}

.productCard {
  width: 14vmax;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgb(48, 48, 48);
  margin: 2vmax;
  transition: all 0.5s;
  padding-bottom: 0.5vmax;
}

.productCard > img {
  width: 14vmax;
}

.productCard > div {
  margin: 0.5vmax;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.productCardSpan {
  margin: 0.5vmax;
  font: 300 0.7vmax "Roboto";
}

.productCard > p {
  font-family: "Roboto";
  font-size: 1.2vmax;
  margin: 1vmax 0.5vmax;
  margin-bottom: 0;
}

.productCard > span {
  margin: 0.5vmax;
  color: tomato;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 1vmax;
}

.productCard:hover {
  box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);

  transform: translateY(-1vmax);
}

@media screen and (max-width: 600px) {
  .productCard > p {
    font-size: 1.7vmax;
  }

  .productCard > div {
    margin: 0vmax;
    display: block;
  }

  .productCard > span {
    font-size: 1.5vmax;
  }

  .productCard > div > span {
    margin: 0 0.5vmax;
    font: 300 1vmax "Roboto";
  }
} */

.loading {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  place-items: center;
  max-width: 100%;
}

.loading > div {
  width: 10vmax;
  height: 10vmax;
  border-bottom: 5px solid #f44647;

  border-radius: 50%;

  -webkit-animation: loadingRotate 800ms linear infinite;

          animation: loadingRotate 800ms linear infinite;
}

@-webkit-keyframes loadingRotate {
  to {
    -webkit-transform: rotateZ(-360deg);
            transform: rotateZ(-360deg);
  }
}

@keyframes loadingRotate {
  to {
    -webkit-transform: rotateZ(-360deg);
            transform: rotateZ(-360deg);
  }
}

.features-area > div > div > div:nth-child(2n) .feature-item {
  background-color: rgba(139, 145, 221, 0.1);
}
.features-area > div > div > div {
  padding: 0;
}
.feature-item {
  text-align: center;
  padding: 50px 20px;
  background-color: #111;
}
.feature-item i {
  font-size: 40px;
  margin-bottom: 20px;
}
.feature-item h4 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 400;
}

.counter-area {
  background-image: url(/static/media/counter-bg.d1222051.jpg);
  background-size: cover;
  background-position: center top;
  position: relative;
  z-index: 0;
}
.counter-area:after {
  position: absolute;
  content: '';
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
}
.single-counter-box {
  position: relative;
  text-align: center;
  background-color: #f44647;
  border-radius: 6px;
  padding: 25px;
}
.single-counter-box .text-wrap .counter-info-text span {
  color: #fff;
  font-weight: 600;
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 10px;
  display: inline-block;
}
.single-counter-box .text-wrap h4 {
  color: #ccc;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
}

.ServiceDetails {
  background-color: rgb(255, 255, 255);
  width: 100vw;
  max-width: 100%;
  padding: 6vmax;
  box-sizing: border-box;
  display: flex;
}

.ServiceDetails > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 2vmax;
  box-sizing: border-box;
  border: 1px solid white;
}

.ServiceDetails > div:last-child {
  align-items: flex-start;
}

.CarouselImage {
  width: 20vmax;
}

.detailsBlock-1 > h2 {
  color: rgb(54, 54, 54);
  font: 600 1.6vmax "Roboto";
}

.detailsBlock-1 > p {
  color: rgba(54, 54, 54, 0.582);
  font: 200 0.6vmax cursive;
}

.detailsBlock-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.205);
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 70%;
  padding: 1vmax 0;
}

.detailsBlock-2-span {
  font: 200 0.8vmax cursive;
  color: rgba(0, 0, 0, 0.699);
}

.detailsBlock-3 {
  width: 70%;
}

.detailsBlock-3 > h1 {
  color: rgba(17, 17, 17, 0.795);
  font: 400 1.8vmax "Franklin Gothic Medium";
  margin: 1vmax 0;
}
.detailsBlock-3-1 {
  display: flex;
  align-items: center;
}

.detailsBlock-3-1-1 > button {
  border: none;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 0.5vmax;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
}
.detailsBlock-3-1-1 > button:hover {
  background-color: rgba(0, 0, 0, 0.767);
}

.detailsBlock-3-1-1 > input {
  border: none;
  padding: 0.5vmax;
  width: 1vmax;
  text-align: center;
  outline: none;
  font: 400 0.8vmax "Roboto";
  color: rgba(0, 0, 0, 0.74);
}

.detailsBlock-3-1 > button:last-child {
  border: none;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
  background-color: tomato;
  font: 500 0.7vmax "Roboto";
  border-radius: 20px;
  padding: 0.5vmax 2vmax;
  margin: 1vmax;
  outline: none;
}

.detailsBlock-3-1 > button:last-child:hover {
  background-color: rgb(214, 84, 61);
}

.detailsBlock-3 > p {
  border-top: 1px solid rgba(0, 0, 0, 0.205);
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  padding: 1vmax 0;
  color: rgba(0, 0, 0, 0.651);
  font: 400 1vmax "Roboto";
  margin: 1vmax 0;
}

.detailsBlock-4 {
  color: rgba(0, 0, 0, 0.897);
  font: 500 1.2vmax sans-serif;
}

.detailsBlock-4 > p {
  color: rgba(0, 0, 0, 0.534);
  font: 300 0.8vmax sans-serif;
}

.submitReview {
  border: none;
  background-color: tomato;
  font: 500 0.7vmax "Roboto";
  border-radius: 20px;
  padding: 0.6vmax 2vmax;
  margin: 1vmax 0;
  color: white;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;
}
.submitReview:hover {
  background-color: rgb(197, 68, 45);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.submitDialog {
  display: flex;
  flex-direction: column;
}
.submitDialogTextArea {
  border: 1px solid rgba(0, 0, 0, 0.082);
  margin: 1vmax 0;
  outline: none;
  padding: 1rem;
  font: 300 1rem "Roboto";
}

.reviewsHeading {
  color: #000000be;
  font: 500 1.4vmax "Roboto";
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  padding: 1vmax;
  width: 20vmax;
  margin: auto;
  margin-bottom: 4vmax;
}
.reviews {
  display: flex;
  overflow: auto;
}

.reviewCard {
  flex: none;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.226);
  border: 1px solid rgba(56, 56, 56, 0.116);
  width: 30vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vmax;
  padding: 3vmax;
}
.reviewCard > img {
  width: 5vmax;
}
.reviewCard > p {
  color: rgba(0, 0, 0, 0.836);
  font: 600 0.9vmax "Roboto";
}
.reviewCardComment {
  color: rgba(0, 0, 0, 0.445);
  font: 300 0.8vmax cursive;
}

.noReviews {
  font: 400 1.3vmax "Gill Sans";
  text-align: center;
  color: rgba(0, 0, 0, 0.548);
}

@media screen and (max-width: 600px) {
  .ServiceDetails {
    flex-direction: column;
    height: unset;
  }

  .ServiceDetails > div:last-child {
    align-items: center;
  }

  .detailsBlock-1 > h2 {
    font-size: 2.8vmax;
    text-align: center;
  }

  .detailsBlock-1 > p {
    text-align: center;
    font-size: 1vmax;
  }

  .detailsBlock-2 {
    justify-content: center;
  }
  .detailsBlock-2 > span {
    font-size: 1.5vmax;
  }

  .detailsBlock-3 > h1 {
    font: 700 3vmax "Franklin Gothic Medium";
    text-align: center;
  }

  .detailsBlock-3-1 {
    flex-direction: column;
  }

  .detailsBlock-3-1-1 {
    padding: 2vmax 0;
  }
  .detailsBlock-3-1-1 > button {
    padding: 1.2vmax;
    width: 4vmax;
  }

  .detailsBlock-3-1-1 > input {
    padding: 1.5vmax;
    width: 3vmax;
    font: 400 1.8vmax "Roboto";
  }

  .detailsBlock-3-1 > button:last-child {
    font: 500 1.7vmax "Roboto";
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

  .detailsBlock-3 > p {
    padding: 2.5vmax 0;
    text-align: center;
    font: 400 2vmax "Roboto";
  }

  .detailsBlock-4 {
    font: 500 2.5vmax sans-serif;
  }

  .detailsBlock-4 > p {
    font: 300 1.8vmax sans-serif;
  }

  .submitReview {
    font: 500 1.7vmax "Roboto";
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

  .reviewCard > p {
    font: 600 3vw "Roboto";
  }
  .reviewCardComment {
    font: 300 5vw cursive;
  }
}

.servicesHeading {
  margin: 2vmax auto;
  width: 15vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.171);
  padding: 2vmax;
  color: rgba(0, 0, 0, 0.678);
  font: 500 1.5vmax 'Roboto';
  text-align: center;
}

.services {
  display: flex;
  flex-wrap: wrap;
  padding: 0 5vmax;
  justify-content: center;
  min-height: 30vh;
}

.paginationBox {
  display: flex;
  justify-content: center;
  margin: 6vmax;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
}

.page-item {
  background-color: rgb(255, 255, 255);
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.178);
  padding: 1vmax 1.5vmax;
  transition: all 0.3s;
  cursor: pointer;
}
.page-item:first-child {
  border-radius: 5px 0 0 5px;
}

.page-item:last-child {
  border-radius: 0 5px 5px 0;
}
.page-link {
  text-decoration: none;
  font: 300 0.7vmax 'Roboto';
  color: rgb(80, 80, 80);
  transition: all 0.3s;
}

.page-item:hover {
  background-color: rgb(230, 230, 230);
}

.page-item:hover .page-link {
  color: rgb(0, 0, 0);
}

.pageItemActive {
  background-color: tomato;
}

.pageLinkActive {
  color: white;
}

.filterBox {
  width: 10vmax;
  position: absolute;
  top: 10vmax;
  left: 4vmax;
}

.categoryBox {
  padding: 0%;
}

.category-link {
  list-style: none;
  color: rgba(0, 0, 0, 0.61);
  font: 400 0.8vmax 'Roboto';
  margin: 0.4vmax;
  cursor: pointer;
  transition: all 0.5s;
}
.category-link:hover {
  color: tomato;
}

.filterBox > fieldset {
  border: 1px solid rgba(0, 0, 0, 0.329);
}

@media screen and (max-width: 600px) {
  .filterBox {
    width: 20vmax;
    position: static;
    margin: auto;
  }

  .page-link {
    font: 300 1.7vmax 'Roboto';
  }
  .category-link {
    font: 400 1.8vmax 'Roboto';
  }
}

.services-breadcrumb {
  background-image: url(/static/media/services.4363cf87.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;
  padding: 220px 0px;
  position: relative;
  z-index: 0;
}
.inner-page-title-area:after {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  top: 0px;
  left: 0px;
  z-index: -1;
}
.inner-page-title-area h1 {
  font-weight: 400;
  font-size: 36px;
  color: #fff;
  margin-top: 83px;
  line-height: 24px;
}
.inner-page-title-area h1 span {
  position: relative;
}
.inner-page-title-area h1 span:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  left: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area h1 span:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  right: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area .breadcrumb {
  margin: auto;
  text-align: center;
  padding: 0px;
  display: inline-block;
  background-color: transparent;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
}
.inner-page-title-area .breadcrumb li {
  float: none;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
.inner-page-title-area .breadcrumb li a {
  color: #f44647;
}
.inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
  color: #fff;
}

.searchBox {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(231, 231, 231);
  position: fixed;
  top: 0%;
  left: 0;
}

.searchBox > input[type="text"] {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.274);
  background-color: white;
  border: none;
  color: rgba(0, 0, 0, 0.637);
  padding: 1vmax 2vmax;
  width: 50%;
  outline: none;
  border-radius: 0%;
  font: 300 1.1vmax cursive;
  box-sizing: border-box;
  height: 8%;
}

.searchBox > input[type="submit"] {
  height: 8%;
  border-radius: 0%;
  background-color: tomato;
  border: none;
  padding: 1vmax;
  width: 10%;
  font: 300 1.1vmax "Roboto";
  cursor: pointer;
  color: white;
  transition: all 0.5s;
}

.searchBox > input[type="submit"]:hover {
  background-color: rgb(55, 97, 214);
}

@media screen and (max-width: 600px) {
  .searchBox > input[type="text"] {
    width: 100%;
    font: 300 4vw cursive;
    height: 10%;
  }

  .searchBox > input[type="submit"] {
    height: 10%;
    width: 30%;
    font: 300 4vw "Roboto";
  }
}

.LoginSignUpContainer {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url(/static/media/signup-login.c68182a2.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;
  margin-top: 92px;
  top: 0%;
  left: 0;
}

.LoginSignUpBox {
  background-color: white;
  width: 25vw;
  height: 60vh !important;
  box-sizing: border-box;
  overflow: hidden;
}
.login_signUp_toggle {
  display: flex;
  height: 3vmax;
}
.login_signUp_toggle > p {
  color: rgba(0, 0, 0, 0.678);
  font-size: 16px !important;
  transition: all 0.5s;
  cursor: pointer;
  display: grid;
  place-items: center;
  width: 100%;
}
.login_signUp_toggle > p:hover {
  color: tomato;
}

.LoginSignUpBox > div > button {
  background-color: tomato;
  height: 3px;
  width: 50%;
  border: none;
  transition: all 0.5s;
}

.loginForm,
.signUpForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 70%;
  transition: all 0.5s;
}

.signUpForm {
  -webkit-transform: translateY(-100%) translateX(-100vmax);
          transform: translateY(-100%) translateX(-100vmax);
}

.loginForm > div,
.signUpForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}
.loginForm > div > input,
.signUpForm > div > input {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font-size: 16px !important;
  outline: none;
}

.loginForm > div > svg,
.signUpForm > div > svg {
  position: absolute;
  -webkit-transform: translateX(1vmax);
          transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.loginForm > a {
  color: rgba(0, 0, 0, 0.651);
  text-decoration: none;
  align-self: flex-end;
  transition: all 0.5s;
  font-size: 16px !important;
}

.loginForm > a:hover {
  color: black;
}

#registerImage > img {
  width: 3vmax;
  border-radius: 100%;
}
#registerImage > input {
  display: flex;
  padding: 0%;
}

#registerImage > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 5vh;
  border: none;
  margin: 0%;
  font-size: 16px !important;
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: rgb(255, 255, 255);
}

#registerImage > input::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

.loginBtn,
.signUpBtn {
  border: none;
  background-color: tomato;
  color: white;
  font-size: 16px !important;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.loginBtn:hover,
.signUpBtn:hover {
  background-color: rgb(179, 66, 46);
}

.shiftToLeft {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.shiftToNeutral {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.shiftToNeutralForm {
  -webkit-transform: translateX(0%) translateY(-100%);
          transform: translateX(0%) translateY(-100%);
}

.shiftToRight {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

@media screen and (max-width: 600px) {
  .LoginSignUpContainer {
    background-color: white;
  }
  .LoginSignUpBox {
    width: 100vw;
    height: 95vh;
  }
  .login_signUp_toggle {
    height: 5vmax;
  }
  .login_signUp_toggle > p {
    font-size: 10px !important;
  }

  .loginForm,
  .signUpForm {
    padding: 5vmax;
  }

  .loginForm > div > input,
  .signUpForm > div > input {
    padding: 2.5vmax 5vmax;
    font-size: 10px !important;
  }

  .loginForm > div > svg,
  .signUpForm > div > svg {
    font-size: 2.8vmax;
  }

  .loginForm > a {
    font-size: 10px !important;
  }

  #registerImage > img {
    width: 50px !important;
    border-radius: 100%;
  }

  #registerImage > input::file-selector-button {
    height: 7vh;
    font-size: 10px !important;
  }

  .loginBtn,
  .signUpBtn {
    font-size: 10px !important;
    padding: 1.8vmax;
  }
}

.header-container {
  padding: 0 5rem;
}

.header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9999;
}
.header .navbar-brand {
  padding: 0px;
  margin: 0px;
}
.header-lover {
  padding: 10px 0px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.header-lover .navbar .nav-item .nav-link {
  font-size: 14px;
  color: #000;
  padding: 5px 14px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.navbar-nav .nav-item{
  margin-top: 15px !important;
}
.header-lover-white .navbar .nav-item .nav-link {
  font-size: 14px;
  color: #202022;
  padding: 5px 14px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.header-lover-white {
  padding: 10px 0px;
  background-color: #fff;
  box-shadow: 0 8px 6px -6px black;
  transition: all 0.3s ease-in-out;
}

.header-lover .navbar {
  padding: 0px 0px;
}
.header-lover .navbar .nav-item .nav-link:hover,
.header-lover .navbar .nav-item.active a {
  color: #f44647;
}
.header-lover .navbar .nav-item.active .dropdown-menu .dropdown-item {
  color: #212529;
}
.header-lover .navbar .nav-item.active .dropdown-menu .dropdown-item:hover {
  color: #fff;
}
.header-lover .navbar .nav-item:last-child .nav-link {
  padding-right: 0px;
}
.header .navbar-toggler span {
  background: #2d2e33;
}
.header .dropdown-menu {
  margin: 0px;
  padding: 0px;
  border-top: 3px solid #f44647;
  border-radius: 0px;
}
.header .dropdown-menu .dropdown-item {
  border-bottom: none !important;
  padding: 12px 20px 12px 30px;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.navbar-collapse {
  margin-top: 10px !important;
}

.header .dropdown-menu .dropdown-item:last-child {
  border-bottom: 0px;
}
.header .dropdown-menu .dropdown-item:hover {
  background-color: #f44647;
  color: #fff;
}
.header .dropdown-menu .dropdown-item:hover:before {
  background-color: #fff;
}
.header .dropdown-menu .dropdown-item:before {
  position: absolute;
  content: '';
  left: 15px;
  background-color: #999;
  top: 50%;
  width: 7px;
  height: 1px;
}
.header .nav-pills .nav-link.active,
.header .nav-pills .show > .nav-link {
  background-color: transparent;
}
/* Navbar Toogle */
.navbar-toggler {
  border: none;
  padding: 10px 6px;
  background-color: #f5f5f5;
  outline: none !important;
}
.navbar-toggler span {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background: #fff;
}
.navbar-toggler span + span {
  margin-top: 4px;
  width: 18px;
}
.navbar-toggler span + span + span {
  width: 10px;
}
/* sticky */
.is-sticky {
  z-index: 9999;
}
.is-sticky .header-lover {
  z-index: 9999 !important;
  background-color: #fff !important;
}
.is-sticky .header-lover .navbar .nav-item .nav-link {
  color: #333;
}
.is-sticky .nav-pills .nav-link.active,
.is-sticky .nav-pills .show > .nav-link {
  color: #f44647 !important;
}
@media screen and (min-width: 768px) {
  /* shows the dropdown menu on hover */
  .navbar .dropdown:hover .dropdown-menu,
  .navbar .dropdown .dropdown-menu:hover {
    display: block !important;
  }
}
/* header social */
.header-social a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  color: #fff;
  border-radius: 100%;
  font-size: 12px;
  background-color: #f44647;
  transition: all 0.3s ease-in-out;
}
.header-social a:hover {
  background-color: #d5292a;
}

.profileContainer {
  display: flex;
  height: 100vh;
  width: 100vw;
  position: relative;
  top: 10%;
  left: 0%;
  max-width: 100%;
  background-color: white;
}

.profile h1{
  margin-left: 300px;
}

.profileContainer > div {
  display: flex;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  padding-left: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profileContainer > div:first-child > h1 {
  color: rgba(0, 0, 0, 0.555);
  font-size: 16px !important;
  -webkit-transform: translateX(-10vmax) translateY(-2vmax);
          transform: translateX(-10vmax) translateY(-2vmax);
}

.profileContainer > div:first-child > img {
  width: 20vmax;
  border-radius: 100%;
  transition: all 0.5s;
}

.profileContainer > div:first-child > img:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.profileContainer > div:first-child > a {
  border: none;
  background-color: tomato;
  font-size: 16px !important;
  color: white;
  text-decoration: none;
  padding: 0.5vmax;
  width: 30%;
  margin: 4vmax;
  text-align: center;
  transition: all 0.5s;
}

.profileContainer > div:first-child > a:hover {
  background-color: rgb(204, 78, 56);
}

.profileContainer > div:last-child {
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 5vmax;
  box-sizing: border-box;
}

.profileContainer > div:last-child > div > h4 {
  color: black;
  font-size: 16px !important;
}

.profileContainer > div:last-child > div > p {
  color: rgba(0, 0, 0, 0.418);
  font-size: 16px !important;
  margin: 0.2vmax;
}

.profileContainer > div:last-child > div:last-child {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.profileContainer > div:last-child > div:last-child > a {
  border: none;
  background-color: rgb(68, 68, 68);
  font-size: 16px !important;
  color: white;
  text-decoration: none;
  padding: 0.5vmax;
  text-align: center;
  transition: all 0.5s;
  margin: 1vmax 0;
}

.profileContainer > div:last-child > div:last-child > a:hover {
  background-color: rgb(31, 31, 31);
}

@media screen and (max-width: 600px) {
  .profileContainer {
    flex-direction: column;
  }

  .profileContainer > div:first-child > h1 {
    font-size: 16px !important;
    -webkit-transform: translateY(-2vmax);
            transform: translateY(-2vmax);
  }

  .profileContainer > div:first-child > a {
    font-size: 16px !important;
    padding: 1vmax;
  }

  .profileContainer > div:last-child {
    text-align: center;
    align-items: center;
  }

  .profileContainer > div:last-child > div > h4 {
    font: 400 2.5vmax "Roboto";
  }

  .profileContainer > div:last-child > div > p {
    font-size: 16px !important;
    margin: 0.5vmax 0;
  }

  .profileContainer > div:last-child > div:last-child > a {
    font-size: 16px !important;
    padding: 1vmax;
    margin: 2vmax 0;
  }
}

.updateProfileContainer {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(231, 231, 231);
  position: relative;
  top: 0%;
  left: 0;
}

.updateProfileBox {
  background-color: white;
  width: 25vw;
  height: 50vh !important;
  box-sizing: border-box;
  overflow: hidden;
}

.updateProfileHeading {
  text-align: center;
  color: rgba(0, 0, 0, 0.664);
  font-size: 16px !important;
  padding: 1.3vmax;
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 50%;
  margin: auto;
}

.updateProfileForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 70%;
  transition: all 0.5s;
}

.updateProfileForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.updateProfileForm > div > input {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font-size: 16px !important;
  outline: none;
}

.updateProfileForm > div > svg {
  position: absolute;
  -webkit-transform: translateX(1vmax);
          transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

#updateProfileImage > img {
  width: 3vmax;
  border-radius: 100%;
  margin: 1vmax;
}
#updateProfileImage > input {
  display: flex;
  padding: 0%;
}

#updateProfileImage > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 5vh;
  border: none;
  margin: 0%;
  font-size: 16px !important;
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: rgb(255, 255, 255);
}

#updateProfileImage > input::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

.updateProfileBtn {
  border: none;
  background-color: tomato;
  color: white;
  font-size: 16px !important;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.updateProfileBtn:hover {
  background-color: rgb(179, 66, 46);
}

@media screen and (max-width: 600px) {
  .updateProfileContainer {
    background-color: white;
  }
  .updateProfileBox {
    width: 100vw;
    height: 95vh;
  }

  .updateProfileForm {
    padding: 5px;
  }

  .updateProfileForm > div > input {
    padding: 2.5vmax 5vmax;
    font-size: 16px !important;
  }

  .updateProfileForm > div > svg {
    font-size: 2.8vmax;
  }

  #updateProfileImage > img {
    width: 50px;
    border-radius: 100%;
  }

  #updateProfileImage > input::file-selector-button {
    height: 7vh;
    font-size: 16px !important;
  }

  .updateProfileBtn {
    font-size: 16px !important;
    padding: 1.8vmax;
  }
}

.updatePasswordContainer {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/signup-login.c68182a2.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;  position: relative;
  top: 0%;
  left: 0;
}

.updatePasswordBox {
  background-color: white;
  width: 25vw;
  height: 70vh !important;
  box-sizing: border-box;
  overflow: hidden;
}

.updatePasswordHeading {
  text-align: center;
  color: rgba(0, 0, 0, 0.664);
  font-size: 20px !important;
}

.updatePasswordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 70%;
  transition: all 0.5s;
}

.updatePasswordForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.updatePasswordForm > div > input {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font-size: 16px !important;
  outline: none;
}

.updatePasswordForm > div > svg {
  position: absolute;
  -webkit-transform: translateX(1vmax);
          transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.updatePasswordBtn {
  border: none;
  background-color: tomato;
  color: white;
  font-size: 16px !important;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.updatePasswordBtn:hover {
  background-color: rgb(179, 66, 46);
}

@media screen and (max-width: 600px) {
  .updatePasswordContainer {
    background-color: white;
  }
  .updatePasswordBox {
    width: 100vw;
    height: 95vh;
  }

  .updatePasswordForm {
    padding: 5vmax;
  }

  .updatePasswordForm > div > input {
    padding: 2.5vmax 5vmax;
    font-size: 10px !important;
  }

  .updatePasswordForm > div > svg {
    font-size: 2.8vmax;
  }

  .updatePasswordBtn {
    font-size: 10px !important;
    padding: 1.8vmax;
  }
}

.forgotPasswordContainer {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/signup-login.c68182a2.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;  position: relative;
  top: 0%;
  left: 0;
}

.forgotPasswordBox {
  background-color: white;
  width: 25vw;
  height: 40vh !important;
  box-sizing: border-box;
  overflow: hidden;
}

.forgotPasswordHeading {
  text-align: center;
  color: rgba(0, 0, 0, 0.664);
  font: 400 1.3vmax "Roboto";
  padding: 1.3vmax;
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 50%;
  margin: auto;
}

.forgotPasswordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 70%;
  transition: all 0.5s;
}

.forgotPasswordForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.forgotPasswordForm > div > input {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font-size: 16px !important;
  outline: none;
}

.forgotPasswordForm > div > svg {
  position: absolute;
  -webkit-transform: translateX(1vmax);
          transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.forgotPasswordBtn {
  border: none;
  background-color: tomato;
  color: white;
  font-size: 16px !important;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.forgotPasswordBtn:hover {
  background-color: rgb(179, 66, 46);
}

@media screen and (max-width: 600px) {
  .forgotPasswordContainer {
    background-color: white;
  }
  .forgotPasswordBox {
    width: 100vw;
    height: 95vh;
  }

  .forgotPasswordForm {
    padding: 5vmax;
  }

  .forgotPasswordForm > div > input {
    padding: 2.5vmax 5vmax;
    font-size: 16px !important;
  }

  .forgotPasswordForm > div > svg {
    font-size: 2.8vmax;
  }

  .forgotPasswordBtn {
    font-size: 16px !important;
    padding: 1.8vmax;
  }
}

.resetPasswordContainer {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/signup-login.c68182a2.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;  position: relative;
  top: 0%;
  left: 0;
}

.resetPasswordBox {
  background-color: white;
  width: 25vw;
  height: 70vh !important;
  box-sizing: border-box;
  overflow: hidden;
}

.resetPasswordHeading {
  text-align: center;
  color: rgba(0, 0, 0, 0.664);
  font-size: 16px !important;
  padding: 1.3vmax;
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 50%;
  margin: auto;
}

.resetPasswordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 70%;
  transition: all 0.5s;
}

.resetPasswordForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.resetPasswordForm > div > input {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font-size: 16px !important;
  outline: none;
}

.resetPasswordForm > div > svg {
  position: absolute;
  -webkit-transform: translateX(1vmax);
          transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.resetPasswordBtn {
  border: none;
  background-color: tomato;
  color: white;
  font-size: 16px !important;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.resetPasswordBtn:hover {
  background-color: rgb(179, 66, 46);
}

@media screen and (max-width: 600px) {
  .resetPasswordContainer {
    background-color: white;
  }
  .resetPasswordBox {
    width: 100vw;
    height: 95vh;
  }

  .resetPasswordForm {
    padding: 5vmax;
  }

  .resetPasswordForm > div > input {
    padding: 2.5vmax 5vmax;
    font-size: 10px !important;
  }

  .resetPasswordForm > div > svg {
    font-size: 2.8vmax;
  }

  .resetPasswordBtn {
    font-size: 10px !important;
    padding: 1.8vmax;
  }
}

.sidebar {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-left: 10rem;
  margin-left: -100px !important;
  height: 100vh;
  padding-right: 3rem;
}

.sidebar > a:first-child {
  padding: 0;
}
.sidebar > a > img {
  width: 100%;
  transition: all 0.5s;
}

.sidebar > a > img:hover {
  -webkit-filter: drop-shadow(0 0 10px tomato);
          filter: drop-shadow(0 0 10px tomato);
}
.sidebar a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.493);
  font: 200 1rem "Roboto";
  padding: 2rem;
  transition: all 0.5s;
}
.sidebar a:hover {
  color: tomato;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.sidebar a > P {
  display: flex;
  align-items: center;
}
.sidebar a > p > svg {
  margin-right: 0.5rem;
}

.MuiTypography-root {
  background-color: #fff !important;
}

.dashboard {
  width: 100vw;
  max-width: 100%;
  display: grid;
  background-color: rgb(255, 255, 255);
  grid-template-columns: 2fr 5fr;
  position: relative;
  margin-top: 92px;
}

.dashboardContainer {
  border-left: 1px solid rgba(0, 0, 0, 0.13);
  background-color: rgb(255, 255, 255);
  padding: 10rem 0;
}

.dashboardContainer > h1 {
  color: rgba(0, 0, 0, 0.733);
  font: 300 2rem "Roboto";
  text-align: center;
  width: 50%;
  padding: 1.5rem;
  margin: auto;
}

.dashboardSummary {
  margin: 2rem 0;
}

.dashboardSummary > div {
  display: flex;
  background-color: white;
  justify-content: center;
}
.dashboardSummary > div > p {
  background-color: rgba(70, 117, 218, 0.932);
  color: white;
  font: 300 1.3rem "Roboto";
  text-align: center;
  padding: 1.5rem;
  width: 100%;
  margin: 0 2rem;
}
.dashboardSummaryBox2 > a {
  color: rgb(0, 0, 0);
  font-size: 10px !important;
  text-align: center;
  background-color: rgb(255, 233, 174);
  text-decoration: none;
  padding: 1.5rem;
  width: 10vmax;
  height: 10vmax;
  margin: 2rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboardSummaryBox2 > a:first-child {
  background-color: rgb(255, 110, 110);
  color: rgb(255, 255, 255);
}

.dashboardSummaryBox2 > a:last-child {
  background-color: rgb(51, 51, 51);
  color: #202022;
}

.lineChart {
  width: 80%;
  margin: auto;
}

.doughnutChart {
  width: 30vmax;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .dashboard {
    grid-template-columns: 1fr;
  }

  .dashboardContainer {
    border-left: none;
  }

  .dashboardSummary > div > p {
    margin: 0;
  }

  .dashboardSummaryBox2 > a {
    padding: 0.5rem;
    margin: 1rem;
    font: 300 0.9rem "Roboto";
  }
}

.serviceListContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: rgb(221, 221, 221);
  border-left: 1px solid rgba(0, 0, 0, 0.158);
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
}

.serviceListTable {
  width: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.267);
}

#serviceListHeading {
  font: 400 2rem 'Roboto';
  padding: 0.5vmax;
  box-sizing: border-box;
  transition: all 0.5s;
  margin: 2rem;
  text-align: center;
}

.newServiceContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: rgb(221, 221, 221);
  border-left: 1px solid rgba(0, 0, 0, 0.158);
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.newServiceContainer h1 {
  color: rgba(0, 0, 0, 0.733);
  font: 300 2rem "Roboto";
  text-align: center;
}

.createServiceForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 3vmax;
  justify-content: space-evenly;
  height: 90%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.267);
}

.createServiceForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}
.createServiceForm > div > input,
.createServiceForm > div > select,
.createServiceForm > div > textarea {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font-size: 20px !important;
  outline: none;
}

.createServiceForm > div > svg {
  position: absolute;
  -webkit-transform: translateX(1vmax);
          transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

#createServiceFormFile > input {
  display: flex;
  padding: 0%;
}

#createServiceFormFile > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 5vh;
  border: none;
  margin: 0%;
  font-size: 16px !important;
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: rgb(255, 255, 255);
}

#createServiceFormFile > input::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

#createServiceFormImage {
  width: 100%;
  overflow: auto;
}

#createServiceFormImage > img {
  width: 5vmax;
  margin: 0 0.5vmax;
}
#createServiceBtn {
  border: none;
  background-color: tomato;
  color: white;
  font-size: 16px !important;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

#createServiceBtn:hover {
  background-color: rgb(179, 66, 46);
}

@media screen and (max-width: 600px) {
  .newServiceContainer {
    background-color: rgb(255, 255, 255);
  }
  .createServiceForm {
    padding: 5vmax;
  }

  .createServiceForm > div > input,
  .createServiceForm > div > select,
  .createServiceForm > div > textarea {
    padding: 2.5vmax 5vmax;
    font-size: 10px !important;
  }

  .createServiceForm > div > svg {
    font-size: 2.8vmax;
  }

  #createServiceFormFile > img {
    width: 50px !important;
    border-radius: 100%;
  }

  #createServiceFormFile > input::file-selector-button {
    height: 7vh;
    font-size: 10px !important;
  }

  #createServiceBtn {
    font: 300 1.9vmax "Roboto";
    padding: 1.8vmax;
  }
}

.serviceReviewsContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border-left: 1px solid rgba(0, 0, 0, 0.158);
  height: 100vh;
}

.serviceReviewsForm {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 3vmax;
  background-color: white;
}

.serviceReviewsFormHeading {
  color: rgba(0, 0, 0, 0.733);
  font: 300 2rem "Roboto";
  text-align: center;
}

.serviceReviewsForm > div {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 2rem;
}
.serviceReviewsForm > div > input {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font: 300 0.9vmax cursive;
  outline: none;
}

.serviceReviewsForm > div > svg {
  position: absolute;
  -webkit-transform: translateX(1vmax);
          transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

@media screen and (max-width: 600px) {
  .serviceReviewsContainer {
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.158);
  }
  .serviceReviewsForm > div > input {
    padding: 2.5vmax 5vmax;
    font: 300 1.7vmax cursive;
  }

  .serviceReviewsForm > div > svg {
    font-size: 2.8vmax;
  }
}

/* map */
.map-box iframe {
  width: 100%;
  height: 295px;
  border: 0px;
  border-radius: 6px;
  overflow: hidden;
}
.contact-info-box {
  border: 2px solid #303030 !important;
  padding: 40px;
  border-radius: 6px;
}
.contact-info li {
  margin-bottom: 12px;
  font-size: 16px;
  color: #fff;
}
.contact-info li i {
  margin-right: 6px;
}
.contact-info li:last-child {
  margin-bottom: 0px;
}
.contact-info li a {
  color: #fff;
}
/* contact social */
.contact-social a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  color: #fff !important;
  border-radius: 100%;
  font-size: 12px;
  background-color: #f44647;
  transition: all 0.3s ease-in-out;
}
.contact-social a:hover {
  background-color: #d5292a;
}
/* contact page box */
.contact-item-box {
  border-radius: 6px;
  overflow: hidden;
  background-color: #0e0e0e;
}
.contact-item-box .contact-item-grid {
  padding: 20px;
}
.contact-item-box .contact-item-grid h4 {
  color: #f44647;
  margin-bottom: 8px;
}
.contact-item-box .contact-item-grid + .contact-item-grid {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.contact-item-box .contact-item-grid a {
  color: #6c757d;
  transition: all 0.3s ease-in-out;
}
.contact-item-box .contact-item-grid a:hover {
  color: #f44647;
}
/* contact form */
.contact-form input,
.contact-form textarea {
  width: 100%;
  display: block;
  border-radius: 6px;
  color: #666666;
  border: 1px solid #f44647;
  background-color: rgba(255, 255, 255, 0.1);
}
.contact-form textarea {
  height: 120px !important;
}
.contact-form label {
  font-size: 14px;
  top: 2px;
}
.contact-form input:focus,
.contact-form textarea:focus {
  border: 1px solid #f44647;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
}
.help-block.with-errors ul {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 10px;
}




.contact-us {
  background-image: url(/static/media/contact-us.4e1646bf.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;
  padding: 220px 0px;
  position: relative;
  z-index: 0;
}
.inner-page-title-area:after {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  top: 0px;
  left: 0px;
  z-index: -1;
}
.inner-page-title-area h1 {
  font-weight: 400;
  font-size: 36px;
  color: #fff;
  margin-top: 83px;
  line-height: 24px;
}
.inner-page-title-area h1 span {
  position: relative;
}
.inner-page-title-area h1 span:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  left: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area h1 span:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  right: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area .breadcrumb {
  margin: auto;
  text-align: center;
  padding: 0px;
  display: inline-block;
  background-color: transparent;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
}
.inner-page-title-area .breadcrumb li {
  float: none;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
.inner-page-title-area .breadcrumb li a {
  color: #f44647;
}
.inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
  color: #fff;
}
.about-img-wrapper .about-images-1 {
  height: 250px !important;
}
.about-img-wrapper .about-images-2 {
  position: relative;
}
.about-img-wrapper .about-images-2:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #f44647;
  z-index: 1;
  opacity: 0.7;
}
.video-btn {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
}
.video-btn i {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}
.video-btn span {
  display: block;
  margin-top: 7px;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 3px;
}
.about-img-wrapper .about-images-1,
.about-img-wrapper .about-images-2,
.about-img-wrapper .about-images-3 {
  height: 230px;
  border-radius: 10px;
  overflow: hidden;
}
.about-img-wrapper .about-images-1 img,
.about-img-wrapper .about-images-2 img,
.about-img-wrapper .about-images-3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.about-img-wrapper .about-images-3 {
  width: 75%;
}
.about-content-text h6 {
  font-size: 12px;
  font-weight: 700;
  color: #f44647;
  letter-spacing: 3px;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.about-content-text h2 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
}



.about-us {
  background-image: url(/static/media/about-us.23708f54.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;
  padding: 220px 0px;
  position: relative;
  z-index: 0;
}
.inner-page-title-area:after {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  top: 0px;
  left: 0px;
  z-index: -1;
}
.inner-page-title-area h1 {
  font-weight: 400;
  font-size: 36px;
  color: #fff;
  margin-top: 83px;
  line-height: 24px;
}
.inner-page-title-area h1 span {
  position: relative;
}
.inner-page-title-area h1 span:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  left: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area h1 span:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  right: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area .breadcrumb {
  margin: auto;
  text-align: center;
  padding: 0px;
  display: inline-block;
  background-color: transparent;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
}
.inner-page-title-area .breadcrumb li {
  float: none;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
.inner-page-title-area .breadcrumb li a {
  color: #f44647;
}
.inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
  color: #fff;
}
.error-text h2 {
	font-size: 100px;
	padding: 0px;
	font-weight: 700;
	color: #f44647;
}
.error-text h4 {
	margin-bottom: 10px;
	color: #f44647;
}

.not-found {
	background-image: url(/static/media/404-error.0d6f6d03.jpg);
	background-repeat: no-repeat;
	background-position: center top;
	text-align: center;
	background-size: cover;
	padding: 220px 0px;
	position: relative;
	z-index: 0;
  }
  .inner-page-title-area:after {
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	width: 100%;
	height: 100%;
	content: '';
	top: 0px;
	left: 0px;
	z-index: -1;
  }
  .inner-page-title-area h1 {
	font-weight: 400;
	font-size: 36px;
	color: #fff;
	margin-top: 83px;
	line-height: 24px;
  }
  .inner-page-title-area h1 span {
	position: relative;
  }
  .inner-page-title-area h1 span:after {
	position: absolute;
	content: '';
	width: 20px;
	height: 2px;
	background-color: #f44647;
	top: 50%;
	left: -35px;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
  }
  .inner-page-title-area h1 span:before {
	position: absolute;
	content: '';
	width: 20px;
	height: 2px;
	background-color: #f44647;
	top: 50%;
	right: -35px;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
  }
  .inner-page-title-area .breadcrumb {
	margin: auto;
	text-align: center;
	padding: 0px;
	display: inline-block;
	background-color: transparent;
	position: absolute;
	bottom: 30px;
	left: 0px;
	right: 0px;
  }
  .inner-page-title-area .breadcrumb li {
	float: none;
	color: #fff;
	font-size: 14px;
	text-transform: uppercase;
	display: inline-block;
  }
  .inner-page-title-area .breadcrumb li a {
	color: #f44647;
  }
  .inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
	color: #fff;
  }
  
.faq-box {
  border-radius: 6px;
  overflow: hidden;
}
.faq-box .accordion-item .accordion-header .accordion-button {
  position: relative;
  text-align: left;
}
.faq-box .accordion-item .accordion-header .accordion-button.collapsed:after {
  position: absolute;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-image: none;
  content: '\f078';
  font-weight: 700;
  font-size: 10px;
  font-family: 'Font Awesome 5 Free';
}
.faq-box .accordion-item .accordion-header .accordion-button:after {
  position: absolute;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-image: none;
  content: '\f077';
  font-weight: 700;
  font-size: 10px;
  font-family: 'Font Awesome 5 Free';
}
.faq-box .accordion-item .accordion-header .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}
.faq-box .accordion-item .accordion-header i {
  position: absolute;
  right: 25px;
  top: 50%;
  font-size: 14px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.faq-box .accordion-item .accordion-header {
  background-color: transparent;
}
.faq-box .accordion-item .accordion-header .accordion-button.collapsed {
  color: #fff !important;
  padding: 20px 20px !important;
  font-size: 18px;
  background-color: #0e0e0e90;
}
.faq-box .accordion-item {
  margin-bottom: 0px;
  background-color: transparent;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
}
.faq-box .accordion-item:last-child {
  margin-bottom: 0px;
  border-bottom: 0px !important;
}
.faq-box .accordion-item .accordion-header {
  padding: 0px;
  border-bottom: 0;
}
.faq-box .accordion-item .accordion-body {
  line-height: 24px;
  padding: 25px 25px 25px 25px;
  color: #fff;
  margin-top: 1px;
  background-color: #0e0e0e90;
}
.faq-box .accordion-item .accordion-header .accordion-button {
  color: #f44647 !important;
  padding: 20px !important;
  border-bottom: 0;
  font-size: 18px;
  border-radius: 0px;
  background-color: #0e0e0e90;
}
.faq-box .accordion-item .accordion-body ul {
  margin-left: 15px;
}
.faq-box .accordion-item .accordion-body ul li {
  list-style: circle;
}
/* have any question */
.have-any-question {
  border-radius: 6px;
  overflow: hidden;
  background-color: #0e0e0e90;
}
.have-any-question .question_form {
  border-top: 2px solid #f44647;
}
.have-any-question h5 {
  font-size: 16px;
}
.have-any-question .question_form input,
.have-any-question .question_form textarea {
  width: 100%;
  display: block;
  border-radius: 6px;
  color: #fff;
  border: 0px;
  background-color: rgba(255, 255, 255, 0.1);
}
.have-any-question .question_form input:focus,
.have-any-question .question_form textarea:focus {
  border: 1px solid #f44647;
}

.faq {
  background-image: url(/static/media/faq.697fb876.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;
  padding: 220px 0px;
  position: relative;
  z-index: 0;
}
.inner-page-title-area:after {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  top: 0px;
  left: 0px;
  z-index: -1;
}
.inner-page-title-area h1 {
  font-weight: 400;
  font-size: 36px;
  color: #fff;
  margin-top: 83px;
  line-height: 24px;
}
.inner-page-title-area h1 span {
  position: relative;
}
.inner-page-title-area h1 span:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  left: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area h1 span:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  right: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area .breadcrumb {
  margin: auto;
  text-align: center;
  padding: 0px;
  display: inline-block;
  background-color: transparent;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
}
.inner-page-title-area .breadcrumb li {
  float: none;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
.inner-page-title-area .breadcrumb li a {
  color: #f44647;
}
.inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
  color: #fff;
}

.privacy-policy-single-content:last-child {
  margin-bottom: 0px;
}
.privacy-policy-single-content h3 {
  color: #f44647;
  font-size: 22px;
  font-weight: 400;
}
.privacy-policy-single-content ul li {
  padding-bottom: 8px;
}
.privacy-policy-single-content ul li:last-child {
  padding-bottom: 0px;
}
.privacy-policy-single-content ul li i {
  color: #f44647;
  padding-right: 6px;
}

.privacy-policy {
  background-image: url(/static/media/privacy-policy.300c4254.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;
  padding: 220px 0px;
  position: relative;
  z-index: 0;
}
.inner-page-title-area:after {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  top: 0px;
  left: 0px;
  z-index: -1;
}
.inner-page-title-area h1 {
  font-weight: 400;
  font-size: 36px;
  color: #fff;
  margin-top: 83px;
  line-height: 24px;
}
.inner-page-title-area h1 span {
  position: relative;
}
.inner-page-title-area h1 span:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  left: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area h1 span:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  right: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area .breadcrumb {
  margin: auto;
  text-align: center;
  padding: 0px;
  display: inline-block;
  background-color: transparent;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
}
.inner-page-title-area .breadcrumb li {
  float: none;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
.inner-page-title-area .breadcrumb li a {
  color: #f44647;
}
.inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
  color: #fff;
}

.terms-condition-single-content:last-child {
  margin-bottom: 0px;
}
.terms-condition-single-content h3 {
  color: #f44647;
  font-size: 22px;
  font-weight: 400;
}
.terms-condition-single-content ul li {
  padding-bottom: 8px;
}
.terms-condition-single-content ul li:last-child {
  padding-bottom: 0px;
}
.terms-condition-single-content ul li i {
  color: #f44647;
  padding-right: 6px;
}

.terms-condition {
  background-image: url(/static/media/terms-condition.5ead9002.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;
  padding: 220px 0px;
  position: relative;
  z-index: 0;
}
.inner-page-title-area:after {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  top: 0px;
  left: 0px;
  z-index: -1;
}
.inner-page-title-area h1 {
  font-weight: 400;
  font-size: 36px;
  color: #fff;
  margin-top: 83px;
  line-height: 24px;
}
.inner-page-title-area h1 span {
  position: relative;
}
.inner-page-title-area h1 span:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  left: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area h1 span:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  right: -35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.inner-page-title-area .breadcrumb {
  margin: auto;
  text-align: center;
  padding: 0px;
  display: inline-block;
  background-color: transparent;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
}
.inner-page-title-area .breadcrumb li {
  float: none;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
.inner-page-title-area .breadcrumb li a {
  color: #f44647;
}
.inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
  color: #fff;
}

.newSiteContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: rgb(221, 221, 221);
  border-left: 1px solid rgba(0, 0, 0, 0.158);
  display: flex;
  padding: 0 5rem;
  padding-bottom: 10rem;
  flex-direction: column;
  height: 100vh;
  overflow: scroll;
}
.newSiteContainer h1 {
  color: rgba(0, 0, 0, 0.733);
  font: 300 2rem 'Roboto';
  text-align: center;
}

.createSiteForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 3vmax;
  margin-top: 50px;
  justify-content: space-evenly;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.267);
  width: 100%;
  background-color: white;
}

.createSiteForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}
.createSiteForm > div > input,
.createSiteForm > div > select,
.createSiteForm > div > textarea {
  padding: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font-size: 20px !important;
  outline: none;
}

.createSiteForm > div > svg {
  position: relative;
  -webkit-transform: translateX(1vmax);
          transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

#createSiteFormFile > input {
  display: flex;
  padding: 0%;
}

#createSiteFormFile > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 5vh;
  border: none;
  margin: 0%;
  font: 400 0.8vmax cursive;
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: rgb(255, 255, 255);
}

#createSiteFormFile > input::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

#createSiteFormImage {
  width: 100%;
  overflow: auto;
}

#createSiteFormImage > img {
  width: 5vmax;
  margin: 0 0.5vmax;
}
#createSiteBtn {
  border: none;
  background-color: tomato;
  color: white;
  font: 300 0.9vmax 'Roboto';
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

#createSiteBtn:hover {
  background-color: rgb(179, 66, 46);
}

@media screen and (max-width: 600px) {
  .newSiteContainer {
    background-color: rgb(255, 255, 255);
  }
  .createSiteForm {
    padding: 5vmax;
  }

  .createSiteForm > div > input,
  .createSiteForm > div > select,
  .createSiteForm > div > textarea {
    padding: 2.5vmax 5vmax;
    font-size: 10px !important;
  }

  .createSiteForm > div > svg {
    font-size: 2.8vmax;
  }

  #createSiteFormFile > img {
    width: 50px !important;
    border-radius: 100%;
  }

  #createSiteFormFile > input::file-selector-button {
    height: 7vh;
    font-size: 10px !important;
  }

  #createSiteBtn {
    font: 300 1.9vmax 'Roboto';
    padding: 1.8vmax;
  }
}

.siteListContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: rgb(221, 221, 221);
  border-left: 1px solid rgba(0, 0, 0, 0.158);
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
}

.siteListTable {
  width: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.267);
}

#siteListHeading {
  font: 400 2rem 'Roboto';
  padding: 0.5vmax;
  box-sizing: border-box;
  transition: all 0.5s;
  margin: 2rem;
  text-align: center;
}

.myQRCode{
    margin-top: 50px;
    padding-bottom: 50px;
}

.qr-code {
    background-image: url(/static/media/qr-code.d698236d.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    text-align: center;
    background-size: cover;
    padding: 220px 0px;
    position: relative;
    z-index: 0;
  }
  .inner-page-title-area:after {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0px;
    left: 0px;
    z-index: -1;
  }
  .inner-page-title-area h1 {
    font-weight: 400;
    font-size: 36px;
    color: #fff;
    margin-top: 83px;
    line-height: 24px;
  }
  .inner-page-title-area h1 span {
    position: relative;
  }
  .inner-page-title-area h1 span:after {
    position: absolute;
    content: '';
    width: 20px;
    height: 2px;
    background-color: #f44647;
    top: 50%;
    left: -35px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .inner-page-title-area h1 span:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 2px;
    background-color: #f44647;
    top: 50%;
    right: -35px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .inner-page-title-area .breadcrumb {
    margin: auto;
    text-align: center;
    padding: 0px;
    display: inline-block;
    background-color: transparent;
    position: absolute;
    bottom: 30px;
    left: 0px;
    right: 0px;
  }
  .inner-page-title-area .breadcrumb li {
    float: none;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
  }
  .inner-page-title-area .breadcrumb li a {
    color: #f44647;
  }
  .inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
    color: #fff;
  }
  
