.header-container {
  padding: 0 5rem;
}

.header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9999;
}
.header .navbar-brand {
  padding: 0px;
  margin: 0px;
}
.header-lover {
  padding: 10px 0px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-lover .navbar .nav-item .nav-link {
  font-size: 14px;
  color: #000;
  padding: 5px 14px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-nav .nav-item{
  margin-top: 15px !important;
}
.header-lover-white .navbar .nav-item .nav-link {
  font-size: 14px;
  color: #202022;
  padding: 5px 14px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-lover-white {
  padding: 10px 0px;
  background-color: #fff;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-lover .navbar {
  padding: 0px 0px;
}
.header-lover .navbar .nav-item .nav-link:hover,
.header-lover .navbar .nav-item.active a {
  color: #f44647;
}
.header-lover .navbar .nav-item.active .dropdown-menu .dropdown-item {
  color: #212529;
}
.header-lover .navbar .nav-item.active .dropdown-menu .dropdown-item:hover {
  color: #fff;
}
.header-lover .navbar .nav-item:last-child .nav-link {
  padding-right: 0px;
}
.header .navbar-toggler span {
  background: #2d2e33;
}
.header .dropdown-menu {
  margin: 0px;
  padding: 0px;
  border-top: 3px solid #f44647;
  border-radius: 0px;
}
.header .dropdown-menu .dropdown-item {
  border-bottom: none !important;
  padding: 12px 20px 12px 30px;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar-collapse {
  margin-top: 10px !important;
}

.header .dropdown-menu .dropdown-item:last-child {
  border-bottom: 0px;
}
.header .dropdown-menu .dropdown-item:hover {
  background-color: #f44647;
  color: #fff;
}
.header .dropdown-menu .dropdown-item:hover:before {
  background-color: #fff;
}
.header .dropdown-menu .dropdown-item:before {
  position: absolute;
  content: '';
  left: 15px;
  background-color: #999;
  top: 50%;
  width: 7px;
  height: 1px;
}
.header .nav-pills .nav-link.active,
.header .nav-pills .show > .nav-link {
  background-color: transparent;
}
/* Navbar Toogle */
.navbar-toggler {
  border: none;
  padding: 10px 6px;
  background-color: #f5f5f5;
  outline: none !important;
}
.navbar-toggler span {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background: #fff;
}
.navbar-toggler span + span {
  margin-top: 4px;
  width: 18px;
}
.navbar-toggler span + span + span {
  width: 10px;
}
/* sticky */
.is-sticky {
  z-index: 9999;
}
.is-sticky .header-lover {
  z-index: 9999 !important;
  background-color: #fff !important;
}
.is-sticky .header-lover .navbar .nav-item .nav-link {
  color: #333;
}
.is-sticky .nav-pills .nav-link.active,
.is-sticky .nav-pills .show > .nav-link {
  color: #f44647 !important;
}
@media screen and (min-width: 768px) {
  /* shows the dropdown menu on hover */
  .navbar .dropdown:hover .dropdown-menu,
  .navbar .dropdown .dropdown-menu:hover {
    display: block !important;
  }
}
/* header social */
.header-social a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  color: #fff;
  border-radius: 100%;
  font-size: 12px;
  background-color: #f44647;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-social a:hover {
  background-color: #d5292a;
}
