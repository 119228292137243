/* footer links */
.footer-links li {
	display: inline-block;
}
.footer-links li a {
	padding: 0 12px;
	font-size: 12px;
	color: #6c757d;
	text-decoration: none;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.footer-links li:first-child a {
	padding-left: 0px;
}
.footer-links li a:hover {
	color: #f44647;
}
/* footer copyright */
.footer-copyright {
	color: #6c757d;
	font-size: 12px;
}