/* map */
.map-box iframe {
  width: 100%;
  height: 295px;
  border: 0px;
  border-radius: 6px;
  overflow: hidden;
}
.contact-info-box {
  border: 2px solid #303030 !important;
  padding: 40px;
  border-radius: 6px;
}
.contact-info li {
  margin-bottom: 12px;
  font-size: 16px;
  color: #fff;
}
.contact-info li i {
  margin-right: 6px;
}
.contact-info li:last-child {
  margin-bottom: 0px;
}
.contact-info li a {
  color: #fff;
}
/* contact social */
.contact-social a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  color: #fff !important;
  border-radius: 100%;
  font-size: 12px;
  background-color: #f44647;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.contact-social a:hover {
  background-color: #d5292a;
}
/* contact page box */
.contact-item-box {
  border-radius: 6px;
  overflow: hidden;
  background-color: #0e0e0e;
}
.contact-item-box .contact-item-grid {
  padding: 20px;
}
.contact-item-box .contact-item-grid h4 {
  color: #f44647;
  margin-bottom: 8px;
}
.contact-item-box .contact-item-grid + .contact-item-grid {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.contact-item-box .contact-item-grid a {
  color: #6c757d;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.contact-item-box .contact-item-grid a:hover {
  color: #f44647;
}
/* contact form */
.contact-form input,
.contact-form textarea {
  width: 100%;
  display: block;
  border-radius: 6px;
  color: #666666;
  border: 1px solid #f44647;
  background-color: rgba(255, 255, 255, 0.1);
}
.contact-form textarea {
  height: 120px !important;
}
.contact-form label {
  font-size: 14px;
  top: 2px;
}
.contact-form input:focus,
.contact-form textarea:focus {
  border: 1px solid #f44647;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
}
.help-block.with-errors ul {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 10px;
}




.contact-us {
  background-image: url(../../../img/bg/contact-us.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;
  padding: 220px 0px;
  position: relative;
  z-index: 0;
}
.inner-page-title-area:after {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  top: 0px;
  left: 0px;
  z-index: -1;
}
.inner-page-title-area h1 {
  font-weight: 400;
  font-size: 36px;
  color: #fff;
  margin-top: 83px;
  line-height: 24px;
}
.inner-page-title-area h1 span {
  position: relative;
}
.inner-page-title-area h1 span:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  left: -35px;
  transform: translateY(-50%);
}
.inner-page-title-area h1 span:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  right: -35px;
  transform: translateY(-50%);
}
.inner-page-title-area .breadcrumb {
  margin: auto;
  text-align: center;
  padding: 0px;
  display: inline-block;
  background-color: transparent;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
}
.inner-page-title-area .breadcrumb li {
  float: none;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
.inner-page-title-area .breadcrumb li a {
  color: #f44647;
}
.inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
  color: #fff;
}