.about-img-wrapper .about-images-1 {
  height: 250px !important;
}
.about-img-wrapper .about-images-2 {
  position: relative;
}
.about-img-wrapper .about-images-2:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #f44647;
  z-index: 1;
  opacity: 0.7;
}
.video-btn {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
}
.video-btn i {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}
.video-btn span {
  display: block;
  margin-top: 7px;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 3px;
}
.about-img-wrapper .about-images-1,
.about-img-wrapper .about-images-2,
.about-img-wrapper .about-images-3 {
  height: 230px;
  border-radius: 10px;
  overflow: hidden;
}
.about-img-wrapper .about-images-1 img,
.about-img-wrapper .about-images-2 img,
.about-img-wrapper .about-images-3 img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}
.about-img-wrapper .about-images-3 {
  width: 75%;
}
.about-content-text h6 {
  font-size: 12px;
  font-weight: 700;
  color: #f44647;
  letter-spacing: 3px;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.about-content-text h2 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
}



.about-us {
  background-image: url(../../../img/bg/about-us.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;
  padding: 220px 0px;
  position: relative;
  z-index: 0;
}
.inner-page-title-area:after {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  top: 0px;
  left: 0px;
  z-index: -1;
}
.inner-page-title-area h1 {
  font-weight: 400;
  font-size: 36px;
  color: #fff;
  margin-top: 83px;
  line-height: 24px;
}
.inner-page-title-area h1 span {
  position: relative;
}
.inner-page-title-area h1 span:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  left: -35px;
  transform: translateY(-50%);
}
.inner-page-title-area h1 span:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #f44647;
  top: 50%;
  right: -35px;
  transform: translateY(-50%);
}
.inner-page-title-area .breadcrumb {
  margin: auto;
  text-align: center;
  padding: 0px;
  display: inline-block;
  background-color: transparent;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
}
.inner-page-title-area .breadcrumb li {
  float: none;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
.inner-page-title-area .breadcrumb li a {
  color: #f44647;
}
.inner-page-title-area .breadcrumb-item + .breadcrumb-item:before {
  color: #fff;
}