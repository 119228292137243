.forgotPasswordContainer {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../img/bg/signup-login.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  background-size: cover;  position: relative;
  top: 0%;
  left: 0;
}

.forgotPasswordBox {
  background-color: white;
  width: 25vw;
  height: 40vh !important;
  box-sizing: border-box;
  overflow: hidden;
}

.forgotPasswordHeading {
  text-align: center;
  color: rgba(0, 0, 0, 0.664);
  font: 400 1.3vmax "Roboto";
  padding: 1.3vmax;
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 50%;
  margin: auto;
}

.forgotPasswordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 70%;
  transition: all 0.5s;
}

.forgotPasswordForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.forgotPasswordForm > div > input {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font-size: 16px !important;
  outline: none;
}

.forgotPasswordForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.forgotPasswordBtn {
  border: none;
  background-color: tomato;
  color: white;
  font-size: 16px !important;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.forgotPasswordBtn:hover {
  background-color: rgb(179, 66, 46);
}

@media screen and (max-width: 600px) {
  .forgotPasswordContainer {
    background-color: white;
  }
  .forgotPasswordBox {
    width: 100vw;
    height: 95vh;
  }

  .forgotPasswordForm {
    padding: 5vmax;
  }

  .forgotPasswordForm > div > input {
    padding: 2.5vmax 5vmax;
    font-size: 16px !important;
  }

  .forgotPasswordForm > div > svg {
    font-size: 2.8vmax;
  }

  .forgotPasswordBtn {
    font-size: 16px !important;
    padding: 1.8vmax;
  }
}
