.counter-area {
  background-image: url(../../../img/bg/counter-bg.jpg);
  background-size: cover;
  background-position: center top;
  position: relative;
  z-index: 0;
}
.counter-area:after {
  position: absolute;
  content: '';
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
}
.single-counter-box {
  position: relative;
  text-align: center;
  background-color: #f44647;
  border-radius: 6px;
  padding: 25px;
}
.single-counter-box .text-wrap .counter-info-text span {
  color: #fff;
  font-weight: 600;
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 10px;
  display: inline-block;
}
.single-counter-box .text-wrap h4 {
  color: #ccc;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
}
